export class AvailableAppointment {
  public start_time: string;
  public site_appointment_type_id: string;
  public practitioner_id: string;
  public duration: number;
  public score: number;
  // public shadow_appointments: Array<AvailableShadowAppointment>;
}

export class AvailableOptionBase {
  public appointments: Array<AvailableAppointment>;
  public total_duration: number;
  public get total_score(): number {
    if (!this.appointments) return 0;

    let total = 0;
    for (const appt of this.appointments) total += appt.score;

    return total;
  }
}

export class AvailableTimeSlotBase {
  public time: string; // was Date
  public options: Array<AvailableOptionBase>;
  public get best_option_score(): number {
    if (!this.options || this.options.length === 0) return 0;
    return Math.max(...this.options.map((option) => option.total_score));
  }
}

export class AvailabilityBase {
  public date: string;
  public available_time_slots: Array<AvailableTimeSlotBase>;

  public get best_option_score(): number {
    if (!this.available_time_slots || this.available_time_slots.length === 0) return 0;
    return Math.max(...this.available_time_slots.map((option) => option.best_option_score));
  }
}

export enum E_IndividuallyAvailable {
  Yes = "Yes",
  No = "No",
  Unknown = "Unknown",
}

export class AvailableIndividuallyBase {
  public site_appointment_type_id: string;
  public available: E_IndividuallyAvailable;
}
