import { Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RecaptchaService {
  public getRecaptchaToken(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const { grecaptcha } = window as any;

      try {
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise
            .execute(environment.GOOGLE_RECAPTCHA_SITE_KEY, { action })
            .then((token: string) => {
              resolve(token);
            })
            .catch((error: any) => {
              Bugsnag.notify(`Failed to generate recaptcha token - ${error}`);

              reject();
            });
        });
      } catch {
        reject();
      }
    });
  }
}
