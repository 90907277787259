/**
 * Http service abstract class

 */

import { Observable, throwError, timer } from "rxjs";
import { catchError, last, map, retry } from "rxjs/operators";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoggerService } from "./logger.service";
import { Enums } from "@apis/_core/types/enums";
import { JWTService } from "./jwt.service";
import Bugsnag from "@bugsnag/js";

/**
 * This class abstracts logic for retrieving data from a service endpoint
 */
export abstract class HttpServiceAbstract {
  public constructor(public http: HttpClient, public loggerService: LoggerService, private _jwtService: JWTService) {}

  public send<T>(url: string, options: any = {}): Observable<T> {
    return this._doRequest(url, options);
  }

  private _doRequest(url: string, options: any): Observable<any> {
    options.observe = "response";
    options.wiithCredentials = true;

    return this.http.request(options.method, url, options).pipe(
      map((res: any) => {
        if (res && res.ok === true) {
          return res.body;
        } else {
          return res;
        }
      }),
      last(), // return last (completed) message to caller
      retry({
        delay: (error, retryCount) => {
          // A status of 503 should happen rarely and a status of 0 should only really happen if there is a network issue
          // so let's retry the request a few times with a slight delay to give it a better chance of working
          if ([0, 503].includes(error.status) && retryCount <= 3) {
            console.error({ httpError: error, retry: retryCount, code: "bd0c50fd" });

            return timer(500);
          }
          return throwError(() => error);
        },
      }),
      // eslint-disable-next-line complexity
      catchError((res: HttpErrorResponse) => {
        console.error({ catchError: res, code: "d4140700" });

        if (res.status === 0) {
          const error = {
            data: "ERROR",
            message: res.message,
            status: res.status,
          };
          // After 3 retries (see mergeMap above), throw the error with additional information to help debug issues in the future.
          return throwError(() => error);
        }

        // Don't handle invalid tokens for public access levels because the patient will be logging in.
        // This would mean that they would be taken back to the start of the login process if they enter the wrong password
        if ([401, 403].includes(res.status) && !this._jwtService.isPublic()) {
          return this._handle401or403(res);
        }

        if (res.status > 399 && res.status < 600) {
          const error = {
            data: res.error && res.error.data ? res.error.data : res.error ? res.error : Enums.API_ERROR_TYPES.SERVER_ERROR,
            message: res.message,
          };
          return throwError(() => error);
        }

        const userMessage = res && res.error && res.error.data ? res.error.data : `HTTP_ERROR`;
        return throwError(() => userMessage);
      })
    );
  }

  private _handle401or403(res: HttpErrorResponse): Observable<Enums.API_ERROR_TYPES> {
    Bugsnag.notify(`${res.status} HTTP error. Invalidating token`);

    this._jwtService.handleInvalidToken();

    const error = res.status === 401 ? Enums.API_ERROR_TYPES.UNAUTHORIZED : Enums.API_ERROR_TYPES.FORBIDDEN;

    return throwError(() => error);
  }
}
