let _isOnUnsupportedPage = false;

export function isOnUnsupportedPage(): boolean {
  return _isOnUnsupportedPage || window.location.href?.indexOf(`/unsupported`) !== -1;
}

export function navigateToUnsupported(): void {
  if (isOnUnsupportedPage()) return;

  _isOnUnsupportedPage = true; // Allow isOnUnsupportedPage to return true immediately and prevent duplicate Bugsnag notifications
  window.location.href = `/unsupported?redirect=${window.location.pathname}`;
}
