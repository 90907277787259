export namespace Enums {
  export enum PMS {
    DENTALLY = "DENTALLY",
    EXACT = "EXACT",
  }
  export enum API_ERROR_TYPES {
    FORBIDDEN = "FORBIDDEN",
    UNAUTHORIZED = "UNAUTHORIZED",
    CUSTOM = "CUSTOM",
    SERVER_ERROR = "SERVER_ERROR",
    HTTP_ERROR = "HTTP_ERROR",
  }

  export enum E_CRON_DAYS {
    MON = 1,
    TUE = 2,
    WED = 3,
    THU = 4,
    FRI = 5,
    SAT = 6,
    SUN = 7,
  }

  export enum E_Signature_Mode {
    TYPE = "TYPE",
    DRAW = "DRAW",
  }

  export enum Google_Recaptcha_Action {
    NEW_PATIENT = "NEW_PATIENT",
    LOGIN = "LOGIN",
  }
}
