export enum E_ChatMessageAction {
  BookAppointment = "BookAppointment",
  Redirection = "Redirection",
}

export class ChatMessageBase {
  public message: {
    text?: string;
    action?: E_ChatMessageAction;
    data?: string;
  };
  public intentName?: string;
  public step?: number;
  public questionId?: string;
  public options?: Array<{
    label: string;
    value: string;
  }>;
  public delay?: number;
}
