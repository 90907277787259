import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { BrandService } from "../services/brand.service";

@Injectable({
  providedIn: "root",
})
export class BookableAppointmentsAnimationResolver {
  constructor(private _brandService: BrandService) {}

  public resolve(route: ActivatedRouteSnapshot): number {
    const default_steps = {
      "": 0,
      location: 1,
      category: 2,
      appointment: 3,
      practitioner: 4,
      "additional-appointments": 5,
      availability: 6,
      "new-patient": 7,
      "new-patient/verify": 8,
      success: 9,
    };

    const appointment_list_first_steps = {
      "": 0,
      category: 1,
      appointment: 2,
      practitioner: 3,
      location: 4,
      "additional-appointments": 5,
      availability: 6,
      "new-patient": 7,
      "new-patient/verify": 8,
      success: 9,
    };

    const path = route.routeConfig?.path || "";

    if (this._brandService.brand.appointment_list_first) return appointment_list_first_steps[path];
    return default_steps[path];
  }
}
