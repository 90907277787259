import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BottomSheetService {
  // Holds the height of the bottom sheet when it is open
  public onBottomSheetOffset = new Subject<number>();
  public onBottomSheetOpen = new BehaviorSubject<boolean>(false);
  public bottomSheetInstances: Array<number> = [];

  public open(): void {
    this.bottomSheetInstances.push(new Date().getTime());
    this.onBottomSheetOpen.next(true);
  }

  public close(): void {
    this.bottomSheetInstances.pop();
    this.onBottomSheetOpen.next(false);
    // Beacuse the bottom sheets use fixed position, maintain the offset of the bottom sheet if there are still instances open to prevent any overlapping content
    if (!this.bottomSheetInstances.length) this.onBottomSheetOffset.next(0);
  }
}
