import { Injectable } from "@angular/core";
import { LocationService } from "./location.service";
import { environment } from "src/environments/environment";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import Bugsnag from "@bugsnag/js";
import { JWTService } from "./jwt.service";
import { PortalInPracticeDataService } from "./portal-in-practice-data.service";

@Injectable({
  providedIn: "root",
})
export class RumService {
  private _rum: AwsRum | undefined;

  constructor(private _locationService: LocationService, private _jwtService: JWTService, private _portalInPracticeDataService: PortalInPracticeDataService) {}

  public setup(): void {
    try {
      const { region, stage, rumIdentityPoolId, rumAppMonitorId } = this._getStageDetails();

      if (!region || !rumIdentityPoolId || !rumAppMonitorId) {
        // If region, identity pool ID or RUM app monitor ID is not set, do not initialize RUM
        return;
      }

      this._createAwsRum(region, rumIdentityPoolId, rumAppMonitorId);
      this._setDefaultSessionAttributes(stage);
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  private _getStageDetails(): {
    region: string | undefined;
    stage: string | undefined;
    rumIdentityPoolId: string | undefined;
    rumAppMonitorId: string | undefined;
  } {
    let region: string | undefined;
    let stage: string | undefined;
    let rumIdentityPoolId: string = environment.RUM_IDENTITY_POOL_ID;
    let rumAppMonitorId: string = environment.RUM_APP_MONITOR_ID;

    if (this._locationService.isPairDomain) {
      const pipData = this._portalInPracticeDataService.getPipData();

      if (pipData) {
        if (pipData.region) {
          ({ region, stage, rumIdentityPoolId, rumAppMonitorId } = pipData);
        }
      }
    } else if (!this._locationService.isNewPatientDomain || (environment.STAGE && !["development", "production"].includes(environment.STAGE))) {
      // Not currently supported at the moment. Will need to make some further changes to allow the region to be determined
      region = environment.REGION;
      stage = environment.STAGE;
    }

    return { region, stage, rumIdentityPoolId, rumAppMonitorId };
  }

  private _getAwsRumConfig(region: string, identityPoolId: string): AwsRumConfig {
    return {
      sessionSampleRate: 1,
      identityPoolId,
      endpoint: `https://dataplane.rum.${region}.amazonaws.com`,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: true,
    };
  }

  private _createAwsRum(region: string, rumIdentityPoolId: string, rumAppMonitorId: string): void {
    this._rum = new AwsRum(rumAppMonitorId, environment.VERSION, region, this._getAwsRumConfig(region, rumIdentityPoolId));
  }

  private _setDefaultSessionAttributes(stage: string | undefined): void {
    const sessionAttributes: Record<string, string | number | boolean> = {
      domain: this._locationService.hostname,
      mode: this._jwtService.isPip() ? "pip" : this._jwtService.getJWT("access_level").toLowerCase(),
    };

    if (stage) {
      sessionAttributes.stage = stage;
    }

    this._rum?.addSessionAttributes(sessionAttributes);
  }
}
