import { Injectable } from "@angular/core";
import { CacheService } from "src/app/shared/services/cache.service";
import { E_PatientFeatures, E_SlotVariantFeature } from "@backend/common/enums/feature-flags.enum";
import { IFeatureFlags } from "@backend/common/interfaces/feature-flags.interface";

const CACHE_KEY = "feature-flags";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagsService {
  private _forceBasketlessBooking = false;

  constructor(private _cacheService: CacheService) {}

  /**
   * Stores feature flags
   *
   * @param featureFlags Feature flags
   */
  public setFeatureFlags(featureFlags: IFeatureFlags): void {
    this._cacheService.set(CACHE_KEY, JSON.stringify(featureFlags));
  }

  public getFeatureFlagValue<T>(feature: E_PatientFeatures, defaultValue: T): T {
    try {
      return this._getFeatureFlag<T>(feature);
    } catch {
      return defaultValue;
    }
  }

  public set forceBasketlessBooking(value: boolean) {
    this._forceBasketlessBooking = value;
  }

  public get slotVariants(): E_SlotVariantFeature {
    try {
      return this._getFeatureFlag<E_SlotVariantFeature>(E_PatientFeatures.SLOT_VARIANTS);
    } catch {
      return E_SlotVariantFeature.FULL;
    }
  }

  public get patientGpDetails(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.PATIENT_GP_DETAILS);
    } catch {
      return false;
    }
  }

  public get emergencyContact(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.EMERGENCY_CONTACT);
    } catch (e) {
      return false;
    }
  }

  public get chatBot(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.CHAT_BOT);
    } catch (e) {
      return false;
    }
  }

  public get logoutOnInactivity() {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.LOGOUT_ON_INACTIVITY);
    } catch (e) {
      return false;
    }
  }

  public get blockMultipleLogins() {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.BLOCK_MULTIPLE_LOGINS);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public get recaptcha(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.RECAPTCHA);
    } catch {
      return false;
    }
  }

  public get captureInsuranceDetails(): boolean {
    try {
      return this._getFeatureFlag<boolean>(E_PatientFeatures.CAPTURE_INSURANCE_DETAILS);
    } catch {
      return false;
    }
  }

  /**
   * Gets the value of a feature flag
   *
   * @param featureFlag Feature for which to get the value
   */
  private _getFeatureFlag<T>(featureFlag: string): T {
    const json = this._cacheService.get(CACHE_KEY);

    if (!json) throw "No feature flags found";

    const featureFlags = JSON.parse(json);

    if (featureFlags[featureFlag] === undefined) throw "Feature flag not set";

    return featureFlags[featureFlag] as T;
  }
}
