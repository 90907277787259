import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { ApplicationRef, ComponentRef, Injectable, EmbeddedViewRef } from "@angular/core";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
import { SLOW_ANIMATION_DURATION } from "../utils/animations";
import { BottomSheetService } from "./bottom-sheet.service";

export enum E_OverlayType {
  MODAL = "MODAL",
  BOTTOM_SHEET = "BOTTOM_SHEET",
}
export class OverlayInstance {
  public component: ComponentType<any>;
  public data?: Record<string, any>;
  public keepOpen? = false; // Can be used to keep an overlay open with another overlay on top (e.g. a confirmation/loader overlay)
  public type?: E_OverlayType = E_OverlayType.MODAL;
}

export enum E_OverlayResult {
  DONE,
  SAVE,
  CONTINUE,
  CANCEL,
  UNDEFINED,
}

interface I_OverlayChange {
  open: boolean;
  result?: E_OverlayResult;
}
interface I_OverlayOpened extends I_OverlayChange {
  open: true;
}
interface I_OverlayClosed extends Required<I_OverlayChange> {
  open: false;
}
type T_OverlayChange = I_OverlayOpened | I_OverlayClosed;

@Injectable({
  providedIn: "root",
})
export class OverlayService {
  public onOverlayChange: Subject<T_OverlayChange> = new Subject<T_OverlayChange>();
  public onOverlayCloseableChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public get onOverlayVisibilityChange(): Observable<boolean> {
    return this.onOverlayChange.pipe(map((change) => change.open));
  }
  private _componentRefs: Array<ComponentRef<ComponentType<any>>> = [];
  public onRecalculateOverlayScroll: Subject<boolean> = new Subject();
  private _onContinueMap = new Map<number, () => void>();
  private _overlayData: Record<string, any>;
  private _type: E_OverlayType | null = null;
  constructor(private _appRef: ApplicationRef, private _bottomSheetService: BottomSheetService, private _overlay: Overlay) {}

  public get type(): E_OverlayType | null {
    return this._type;
  }

  public get isCloseable(): boolean {
    return this.onOverlayCloseableChange.value;
  }

  public set isCloseable(value: boolean) {
    this.onOverlayCloseableChange.next(value);
  }

  private _onContinue(): void {
    const callback = this._onContinueMap[this._componentRefs.length];
    delete this._onContinueMap[this._componentRefs.length];
    if (callback) callback();
  }

  public open(overlay: OverlayInstance, onContinue?: () => void): void {
    this.isCloseable = true;
    this._type = overlay.type || null;
    this._overlayData = overlay.data || {};
    // If there is already an overlay open, close it
    if (this._componentRefs.length && !overlay.keepOpen) this.close();

    const overlayRef = this._overlay.create();
    const opening = new ComponentPortal(overlay.component);
    const componentRef = overlayRef.attach(opening);

    this._componentRefs.push(componentRef);
    this._onContinueMap[this._componentRefs.length] = onContinue;
    this._appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.onOverlayChange.next({ open: true });
    document.body.appendChild(domElem);
    // Wait for Angular change detection to finish before we open the bottom sheet
    setTimeout(() => {
      if (this._type === E_OverlayType.BOTTOM_SHEET) this._bottomSheetService.open();
    }, 0);
  }

  public get data(): Record<string, any> {
    return this._overlayData;
  }

  public close(result: E_OverlayResult = E_OverlayResult.UNDEFINED): void {
    if (!this._componentRefs.length) return;

    if (result === E_OverlayResult.CONTINUE) this._onContinue();

    const closing = this._componentRefs.pop();

    // If the overlay is a bottom sheet, then let the bottom sheet component handle the closing and animations
    if (this._type === E_OverlayType.BOTTOM_SHEET) this._bottomSheetService.close();

    // Wait for the bottom sheet animation to finish before we detach the component from the DOM
    if (closing) {
      setTimeout(
        () => {
          this._appRef.detachView(closing.hostView);
          closing.destroy();
          this._type = null;
        },
        this._type === E_OverlayType.BOTTOM_SHEET ? SLOW_ANIMATION_DURATION : 0
      );
    }

    this.onOverlayChange.next({ open: false, result });
  }

  public closeAll(result: E_OverlayResult = E_OverlayResult.UNDEFINED): void {
    if (!this._componentRefs.length) return;
    if (result === E_OverlayResult.CONTINUE) this._onContinue();
    for (const _ of this._componentRefs) {
      this.close();
    }
  }
}
