import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { CommonService } from "../services/common.service";
@Pipe({ name: "practiceCurrency", standalone: true })
export class PracticeCurrencyPipe implements PipeTransform {
  constructor(private _commonService: CommonService, private _currencyPipe: CurrencyPipe) {}

  transform(value: string, digitsInfo = "1.2-2"): string {
    return this._currencyPipe.transform(value, this._commonService.practice.currency_code, "symbol-narrow", digitsInfo) || "";
  }
}
