import { Injectable } from "@angular/core";
import { E_Referrer } from "@backend/common/enums/referrer";
import { CacheService } from "./cache.service";

const REFERRER_KEY = "referrer";

@Injectable({
  providedIn: "root",
})
export class ReferrerService {
  constructor(private _cacheService: CacheService) {}

  public set referrer(value: E_Referrer) {
    this._cacheService.setSession(REFERRER_KEY, value);
  }

  public get referrer(): E_Referrer {
    return (this._cacheService.getSession(REFERRER_KEY) || E_Referrer.UNKNOWN) as E_Referrer;
  }
}
