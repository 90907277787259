import { E_StorageType, StorageAdapter } from "./local-storage.util";
import { ICacheStorage } from "../interfaces/cache-storage.interface";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BrowserStorageFactory {
  public create(storageType: E_StorageType): ICacheStorage {
    return new StorageAdapter(storageType);
  }
}
