import { PractitionerBase } from "@backend/graph/practitioners/practitioner-base";
import forOwn from "lodash/forOwn";

export class PractitionerEntry extends PractitionerBase {
  constructor(baseItem?: PractitionerBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
