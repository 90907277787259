import { isDefined } from "@shared/utils";
import { filter, first, Observable, switchMap } from "rxjs";

export function filterDefined<T>(): (source$: Observable<null | undefined | T>) => Observable<T> {
  return (source$: Observable<null | undefined | T>) => source$.pipe(filter(isDefined));
}

export function waitFor<T>(signal: Observable<any>) {
  return (source: Observable<T>) =>
    signal.pipe(
      first(),
      switchMap((_) => source)
    );
}
