import { PatientBase } from "../patients/patient-base";

export type T_Patient_Details = Pick<
  PatientBase,
  | "acquisition_source_id"
  | "address_line_1"
  | "address_line_2"
  | "address_line_3"
  | "county"
  | "email_address"
  | "emergency_contact_name"
  | "emergency_contact_phone"
  | "emergency_contact_phone_country"
  | "emergency_contact_relationship"
  | "gp_practice_name"
  | "gp_practice_address_line_1"
  | "gp_practice_address_line_2"
  | "gp_practice_address_line_3"
  | "gp_practice_address_line_4"
  | "gp_practice_address_line_5"
  | "gp_practice_postcode"
  | "gp_practice_phone"
  | "gp_practice_phone_country"
  | "home_phone"
  | "home_phone_country"
  | "marketing"
  | "mobile_phone"
  | "postcode"
  | "preferred_phone_number"
  | "town"
  | "work_phone"
  | "email_address"
  | "last_name"
  | "mobile_phone_country"
  | "first_name"
  | "nhs_number"
>;

export type T_Patient_Details_Fields = keyof Partial<T_Patient_Details>;

export class PatientDetailsBase {
  public details: T_Patient_Details;
}

// The order of this enum is important as it is used to determine the order of the sections in the patient details overview
export enum E_PatientDetailsSection {
  Name = "name",
  Address = "address",
  Phone = "phone",
  Email = "email",
  EmergencyContact = "emergency-contact",
  PatientGpDetails = "patient-gp-details",
  Marketing = "marketing",
  AcquisitionSource = "acquisition-source",
}

export interface I_MissingPatientDetails {
  sections: Array<E_PatientDetailsSection>;
  fields: Array<T_Patient_Details_Fields>;
}

export interface I_PatientDetailsRequiredFields {
  section: E_PatientDetailsSection;
  fields: Array<T_Patient_Details_Fields>;
}
