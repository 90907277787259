import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import dayjs from "dayjs";
import { ChatService, I_ChatMessage } from "./chat.service";
import { SHARED } from "src/app/shared/shared";
import { FormsModule } from "@angular/forms";
import { FeatureFlagsService } from "../shared/services/feature-flags.service";
import { SubSink } from "subsink";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroChatBubbleLeftRight, heroXMark, heroPaperAirplane } from "@ng-icons/heroicons/outline";

@Component({
  selector: "dentr-chat",
  templateUrl: "./chat.component.html",
  standalone: true,
  host: {
    class: "fixed bottom-10 right-0 z-50",
  },
  imports: [SHARED, FormsModule, NgIconComponent],
  providers: [provideIcons({ heroChatBubbleLeftRight, heroXMark, heroPaperAirplane })],
})
export class ChatComponent implements OnInit, OnDestroy {
  public enabled = false;

  public showChatWindow = false;

  public message = "";

  public messages = new Array<I_ChatMessage>();

  public unreadCount = 0;

  @ViewChild("chatMessages", { static: false })
  private _chatWindow: ElementRef<HTMLDivElement>;

  @ViewChild("chatInput", { static: false })
  private _chatInput: ElementRef<HTMLInputElement>;

  private _subs = new SubSink();

  constructor(public chatService: ChatService, private _featureFlagsService: FeatureFlagsService) {
    this.messages = [];

    this.enabled = this._featureFlagsService.chatBot;
  }

  public ngOnInit(): void {
    this._subs.sink = this.chatService.onMessage.subscribe((message) => {
      this._addTextMessage(message);
    });

    this._subs.sink = this.chatService.onShowChatWindow.subscribe((show) => {
      this.showChatWindow = show;

      if (show) {
        this.unreadCount = 0;
        this._scrollToBottom();

        setTimeout(() => {
          this._chatInput.nativeElement.focus();
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public toggleChatWindow(): void {
    this.chatService.toggleChatWindow();
  }

  public onKeyUp(event: KeyboardEvent): void {
    if (event.key == "Enter") this.sendMessage();
  }

  public selectOption(option: string): void {
    this.chatService.sendMessage(option);
  }

  public sendMessage(): void {
    if (!this.message) {
      this._chatInput?.nativeElement.focus();
      return;
    }

    this._addTextMessage({
      message: this.message,
      from: null,
      timestamp: dayjs(),
    });

    this.chatService.sendMessage(this.message);

    this.message = "";
  }

  private _addTextMessage(message: I_ChatMessage) {
    setTimeout(() => {
      this.messages.push(message);
      this._scrollToBottom();
    }, message.delay ?? 0);
  }

  private _scrollToBottom(): void {
    setTimeout(() => {
      if (this._chatWindow) this._chatWindow.nativeElement.scrollTop = this._chatWindow.nativeElement.scrollHeight;
    });
  }
}
