import { ICacheStorage } from "../interfaces/cache-storage.interface";
import { navigateToUnsupported } from "./unsupported";

export enum E_StorageType {
  LOCAL_STORAGE = "localStorage",
  SESSION_STORAGE = "sessionStorage",
}
export class StorageAdapter implements ICacheStorage {
  private _storageType: string | number;
  constructor(storageType: E_StorageType) {
    this._storageType = storageType;
    this._getStorage();
  }

  public getItem(key: string): any {
    return this._getStorage()?.getItem(key);
  }

  public setItem(key: string, data: any): void {
    this._getStorage()?.setItem(key, data);
  }

  public removeItem(key: string): void {
    this._getStorage()?.removeItem(key);
  }

  public clear(): void {
    this._getStorage()?.clear();
  }

  public _getStorage(): Storage | null {
    try {
      const storage = window && window[this._storageType];
      if (storage) return storage;
    } catch (err) {}

    navigateToUnsupported();

    return null;
  }
}
