import { SHARED } from "src/app/shared/shared";
import { Component, Input, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { CommonService } from "../../services/common.service";
import { OverlayService } from "../../services/overlay.service";
import { ModalComponent } from "../modal/modal.component";
import { LoginTermsDefaultComponent } from "./login-terms-default/login-terms-default.component";
import { LoginTermsGBComponent } from "./login-terms-gb/login-terms-gb.component";
import { PrivacyPolicyDefaultComponent } from "./privacy-policy-default/privacy-policy-default.component";
@Component({
  selector: "dentr-legal",
  templateUrl: "./legal.component.html",
  standalone: true,
  imports: [ModalComponent, LoginTermsDefaultComponent, LoginTermsGBComponent, PrivacyPolicyDefaultComponent, SHARED],
})
export class LegalComponent implements OnInit {
  @Input() is_modal = false;
  public iso_country_code = "GB";
  public show_gb_terms = false;
  private _subs = new SubSink();
  constructor(private _commonService: CommonService, private _overlayService: OverlayService) {}

  ngOnInit() {
    // This component can also be opened via the overlay service so we need to check if the we want to house the component in a modal
    if (this._overlayService?.data?.is_modal) this.is_modal = true;
    this.iso_country_code = this._commonService.practice.iso_country_code;
    this.show_gb_terms = ["GB", "IE", "IRL", "JE"].includes(this.iso_country_code);
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public closeModal(): void {
    this._overlayService.close();
  }
}
