import { E_Patient_Actions_Type } from "../../graph/patient_actions/patient-action-base";
import { I_Referrer } from "../interfaces/referrer";
import { Metric } from "./metric";

export class AppointmentMetricProperties {
  public recalls_due: E_Patient_Actions_Type[];
  public category_name: string;
  public referrer: I_Referrer;
  public appointment_name: string;
  public deposit: number | null;
  public nhs: boolean;
  public price: number | null;
  public book_together: boolean;
  public number_of_available_slots: number;
  public first_availability_days_ahead: number | null;
  public site_id: string;
  public hour_of_day: number;
  public days_ahead: number;
  public payment_method: string | undefined;
  public practitioner_name: string;
  public practitioner_id: string;
  public new_patient: boolean;
  public rebooking: boolean;
}

/**
 * These are special FE metrics for the appointment booking flow
 * For each subsequent metric, we send all the properties sent for metrics earlier in the flow
 */
export namespace APPOINTMENT_METRIC {
  export class AppointmentBookingStarted extends Metric {
    constructor(properties: AppointmentMetricProperties) {
      const { referrer, recalls_due } = properties;

      super("Appointment Booking Started", null, { recalls_due, referrer });
    }
  }

  export class AppointmentCategorySelected extends Metric {
    constructor(properties: AppointmentMetricProperties) {
      const { category_name, rebooking } = properties;

      const setProperties: Partial<AppointmentMetricProperties> = {
        ...new AppointmentBookingStarted(properties).properties,
        category_name,
        rebooking,
      };

      //Site id is not known - they could be booking an appointment at any site at the practice
      super("Appointment Category Selected", null, setProperties);
    }
  }

  export class AppointmentTypeSelected extends Metric {
    constructor(properties: AppointmentMetricProperties) {
      const { appointment_name, deposit, book_together, nhs, price } = properties;

      const setProperties: Partial<AppointmentMetricProperties> = {
        ...new AppointmentCategorySelected(properties).properties,
        appointment_name,
        deposit,
        book_together,
        nhs,
      };
      if (price) setProperties.price = price; // price can be null

      //Site id is not known - they could be booking an appointment at any site at the practice
      super("Appointment Type Selected", null, setProperties);
    }
  }

  export class AppointmentAvailabilitySearched extends Metric {
    constructor(properties: AppointmentMetricProperties) {
      const { number_of_available_slots, first_availability_days_ahead, new_patient } = properties;

      const setProperties: Partial<AppointmentMetricProperties> = {
        ...new AppointmentTypeSelected(properties).properties,
        number_of_available_slots,
        new_patient,
      };

      if (first_availability_days_ahead) setProperties.first_availability_days_ahead = first_availability_days_ahead;

      super("Appointment Availability Searched", null, setProperties);
    }
  }

  export class AppointmentSlotConfirmed extends Metric {
    constructor(properties: AppointmentMetricProperties) {
      const { site_id, hour_of_day, days_ahead, practitioner_name, practitioner_id } = properties;

      const setProperties: Partial<AppointmentMetricProperties> = {
        ...new AppointmentAvailabilitySearched(properties).properties,
        hour_of_day,
        days_ahead,
        practitioner_name,
        practitioner_id,
      };

      super("Appointment Slot Confirmed", site_id, setProperties);
    }
  }

  export class AppointmentBooked extends Metric {
    constructor(properties: AppointmentMetricProperties) {
      const { site_id, payment_method } = properties;

      const setProperties: Partial<AppointmentMetricProperties> = {
        ...new AppointmentSlotConfirmed(properties).properties,
      };

      if (payment_method) setProperties.payment_method = payment_method;

      super("Appointment Booked", site_id, setProperties);
    }
  }
}
