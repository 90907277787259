export const DENTALLY_BLUE_COLOUR = "#0071EB";
export const UPLOADED_IMAGE_MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const UPLOADED_IMAGE_ALLOWED_IMAGE_TYPES = ["png", "jpeg", "jpg", "webp"];
export const DATE_OF_BIRTH_CHECK_ERROR_MESSAGE =
  "The date of birth you entered does not match our records. Please contact the practice if you need assistance.";
// Whenever we add an event for a session, we update the ttl to be an hour in the future. This means that a session 'finishes' an hour after the latest event
export const SECONDS_TO_CACHE_EVENTS_BEFORE_SENDING_METRICS = 60 * 60;
export const ICON_SIZES = ["16", "32", "48", "144"];
export const PIP_PAIRING_TABLE_NAME = "global-portal-in-practice-pairing";
export const PIP_DEVICES_TABLE_NAME = "global-portal-in-practice-devices";
