<div class="text-primary cursor-pointer p-3" *ngIf="!showChatWindow && enabled" (click)="toggleChatWindow()">
  <span
    *ngIf="unreadCount > 0"
    class="unread-count absolute top-[4px] left-[4px] rounded-full border-4 border-solid border-white bg-red-500 py-1 px-2 text-xs text-white"
    >{{ unreadCount }}</span
  >
  <span class="text-[3rem]">
    <ng-icon name="heroChatBubbleLeftRight"></ng-icon>
  </span>
</div>

<div *ngIf="showChatWindow && enabled" class="h-screen w-screen p-4 sm:h-[400px] sm:w-[400px]">
  <div class="flex h-full w-full flex-col rounded-lg border border-solid border-gray-200 bg-gray-100 shadow">
    <div class="bg-primary text-primary-contrast flex flex-row justify-between rounded-t-lg py-2 px-4">
      <span class="flex flex-row">
        <span class="mt-1 mr-2 text-[1rem]">
          <ng-icon name="heroChatBubbleLeftRight"></ng-icon>
        </span>
        Chat
      </span>

      <span class="text-lg">
        <ng-icon class="cursor-pointer" (click)="toggleChatWindow()" name="heroXMark"></ng-icon>
      </span>
    </div>

    <div class="imessage mx-auto mb-3 flex max-w-[600px] flex-1 flex-col overflow-auto py-2 px-5" #chatMessages>
      <div *ngFor="let message of messages" class="m-2 flex flex-col">
        <p
          class="
            chat-message from-{{ message.from ? 'them' : 'me' }} relative max-w-[75%] break-words rounded-lg py-2 px-3 my-1 w-fit
            before:absolute before:bottom-[-0.1rem] before:h-1 before:transform before:translate-x-[-0.1rem]
            after:absolute after:bottom-[-0.1rem] after:w-[10px] after:h-1 after:bg-gray-100 after:transform after:translate-x-[-30px] after:translate-y-[-2px]
          "
          [innerHTML]="message.message"
          [ngClass]="{
            'bg-primary text-primary-contrast self-end': !message.from,
            'before:border-primary before:right-[-0.35rem] before:rounded-bl before:border-r-[1rem] before:border-solid': !message.from,
            'after:right-[-40px] after:rounded-bl': !message.from,
            'items-end bg-gray-200 text-black': message.from,
            'before:left-[-0.35rem] before:rounded-br-md before:border-l-[1rem] before:border-solid before:border-gray-200': message.from,
            'after:left-[20px] after:rounded-br-md': message.from
          }"
        ></p>

        <div *ngIf="message.options?.length" class="chat-options mt-2 text-center">
          <button
            *ngFor="let option of message.options"
            (click)="selectOption(option.value)"
            class="btn-secondary w-full min-w-[50px] rounded-none border-t-0 border-solid border-gray-400 first:rounded-t-lg first:rounded-b-none first:border-t last:rounded-b-lg last:rounded-t-none"
          >
            {{ option.label }}
          </button>
        </div>
      </div>
    </div>

    <div class="relative flex flex-row p-3">
      <div
        class="typing absolute top-[-20px] left-[10px] inline-block p-3"
        [ngClass]="{ 'is-typing-init is-typing-active': chatService.onTyping | async }"
        *ngIf="chatService.onTyping | async"
      >
        <span class="typing__bullet inline-block h-1 w-1 rounded-full bg-black"></span>
        <span class="typing__bullet inline-block h-1 w-1 rounded-full bg-black"></span>
        <span class="typing__bullet inline-block h-1 w-1 rounded-full bg-black"></span>
      </div>

      <input class="mr-3 flex-1 border border-solid border-gray-200 p-2" [(ngModel)]="message" (keyup)="onKeyUp($event)" #chatInput />

      <button class="text-primary" (click)="sendMessage()">
        <ng-icon name="heroPaperAirplane"></ng-icon>
      </button>
    </div>
  </div>
</div>
