<h2 class="heading-xxl mb-4">Patient portal Terms of Use</h2>
<p>
  <span class="font-normal"
    >This online portal ("Portal") provides a means of collecting important information requested by your dental care provider, which includes both dental
    practices and referral centres where applicable ("Dental Care Provider"), from you and passes that information to your Dental Care Provider. The Portal also
    interacts with the software used by your Dental Care Provider and accesses certain limited information from your dental records to assist you with the
    completion of subsequent forms on the Portal. The Portal is a platform supplied to your Dental Care Provider who has contracted with Henry Schein One
    Australia (ABN 96 091 413 599) or Henry Schein One New Zealand (NZBN 9429039144676) ("Portal Provider") to provide the Portal on behalf of your Dental Care
    Provider.
  </span>
</p>
<p>
  <strong class="block">By using the Portal you accept these terms</strong
  ><span class="font-normal"
    >By using the Portal, you confirm that you accept these terms of use (<strong>"Portal Terms"</strong>) in relation to your use of the Portal and you are
    entering into an agreement with the company indicated in the table below:
  </span>
</p>
<ul>
  <li>if your Dental Care Provider is in Australia, with Henry Schein One Australia (ABN 96 091 413 599); or</li>
  <li>if your Dental Care Provider is in New Zealand or any other country, Henry Schein One New Zealand (NZBN 9429039144676),</li>
</ul>
<p>(each referred to as <strong>"Portal Provider"</strong>)</p>
<p>If you do not agree to these Portal Terms, you must not use the Portal.</p>
<p>
  <strong class="block">Privacy Policy</strong
  ><span class="font-normal"
    >The Portal Provider will handle your personal information and health information (together referred to as personal information in these Portal Terms) that
    is entered via the Portal in accordance with applicable laws and the Portal Provider's privacy policy, which can be found below ("Privacy Policy").
  </span>
</p>

<p>
  <strong class="block">Dental Care Provider</strong
  ><span class="font-normal"
    >Information entered into the Portal will be provided to your Dental Care Provider. Please refer to your Dental Care Provider's terms and conditions and
    privacy policy for details as to how the Dental Care Provider may use your personal information, your rights to request access to, rectification, erasure or
    restriction of processing and how to contact them regarding your legal rights. In all cases, your first point of contact should be your Dental Care
    Provider. The Portal Provider's sole use and access to the information entered into the Portal is for processing on behalf of your Dental Care Provider for
    the Dental Care Provider's legitimate interests in providing dental care to you in accordance with Portal Provider's contract with your Dental Care
    Provider, these Portal Terms and the Privacy Policy.
  </span>
</p>

<p>
  <strong class="block">Adding Your Information to the Portal</strong
  ><span class="font-normal"
    >Whenever you make use of a feature that allows you to enter your information into the Portal, you must comply with the standards set out below. Any
    information provided must to your knowledge:
  </span>
</p>
<ul>
  <li class="font-normal">
    <span class="font-normal">be current, true, accurate (where it states facts), supportable and complete; and</span>
  </li>
  <li class="font-normal">
    <span class="font-normal">comply with the applicable Australian or New Zealand laws and the applicable laws in any country from which it is posted.</span>
  </li>
</ul>
<p>
  <span class="font-normal"
    >You acknowledge that any information you supply must comply with the above, and that you will be responsible for any information supplied.
  </span>
</p>
<p>
  <span class="font-normal"
    >Your information may also be entered into the Portal by family members, legal guardians or a person you have authorised to provide your personal
    information to us and your Dental Care Provider.
  </span>
</p>
<p>
  <span class="font-normal"
    >You must not provide personal information or health information of another person through the Portal unless you are authorised by that person to do so and
    you have ensured that the person is aware of and agrees to the matters set out in these Portal Terms, the Privacy Policy and the privacy policy of the
    Dental Care Provider. You warrant to us that you have permission to enter the personal information and health information of that other person.
  </span>
</p>

<p>
  <strong class="block">You must keep your access credentials safe.</strong
  ><span class="font-normal"
    >If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures (such as
    a link in an email), you must treat such information as confidential. If you know or suspect that anyone other than you, or a designated confidant, has
    accessed the portal you must promptly notify your Dental Care Provider immediately.
  </span>
</p>

<p>
  <strong class="block">Dental Care Provider and third party websites</strong
  ><span class="font-normal"
    >Where the Portal contains links to other websites and resources provided by your Dental Care Provider or any other third party, your Dental Care Provider
    or that other third party is solely responsible for any websites associated with the Portal.
  </span>
</p>
<p>
  <strong class="block">Use of your personal information.</strong
  ><span class="font-normal">When you enter your information to the Portal, you acknowledge the following uses of your information:</span>
</p>
<ul>
  <li class="font-normal">
    <span class="font-normal"
      >to enable the transfer by Portal Provider of your information to your Dental Care Provider, in accordance with these Portal Terms, the Privacy Policy and
      your Dental Care Provider's privacy policy.
    </span>
  </li>
  <li class="font-normal"><span class="font-normal">To enable Portal Provider </span><strong>to only</strong><span class="font-normal">:</span></li>
  <ul>
    <li class="font-normal">
      <span class="font-normal"
        >Update your Dental Care Provider's dental records with information that you provide; and extract content from your dental record to assist you with the
        completion of subsequent forms on the Portal.
      </span>
    </li>
  </ul>
  <li class="font-normal">
    <span class="font-normal"
      >The only actions undertaken by Portal Provider with your data is for the purpose of transferring information between you and your Dental Care Provider.
    </span>
  </li>
  <li class="font-normal">
    <span class="font-normal"
      >to enable Portal Provider to update your Dental Care Provider's dental records with information that you provide and extract information from your dental
      record to assist you with the completion of subsequent forms on the Portal.
    </span>
  </li>

  <li class="font-normal">
    <span class="font-normal"
      >The only actions undertaken by Portal Provider with your data are for the purpose of transferring information between you and your Dental Care Provider
      and the uses or disclosures set out in the Privacy Policy.</span
    >
  </li>
</ul>
<p>
  <span class="font-normal"
    >Please contact your Dental Care Provider or refer to the privacy policy of your Dental Care Provider (which may be accessible from a link on the Portal)
    for details of how your Dental Care Provider handles and protects your personal information once transferred to them through the Portal.
  </span>
</p>
<p>
  <strong class="block">How long do we retain your information?</strong
  ><span class="font-normal">
    Information is retained on the Portal for no longer than necessary to allow you to complete your forms, complete patient check-in and review any
    post-appointment information. Completed forms are held by your Dental Care Provider with your dental records.
  </span>
</p>
<p>
  <span class="font-normal"> There is no retention of information on this application when the processing activity is completed. </span>
</p>

<p>
  <strong class="block">Prohibited uses of the Portal</strong
  ><span class="font-normal">You may use the Portal only for lawful purposes. You may not use the Portal:</span>
</p>
<ul>
  <li class="font-normal">
    <span class="font-normal">in any way that breaches any applicable local, national or international law or regulation; </span>
  </li>
  <li class="font-normal">
    <span class="font-normal">in any way that is fraudulent;</span>
  </li>
  <li class="font-normal">
    <span class="font-normal">to harm or with the attempt to harm another person in any way;</span>
  </li>
  <li class="font-normal">
    <span class="font-normal"
      >to knowingly transmit any data, send or upload any harmful programs or similar computer code designed to adversely affect the operation of any computer
      software or hardware;
    </span>
  </li>
  <li class="font-normal">
    <span class="font-normal">to infringe any other person's intellectual property or privacy rights; or </span>
  </li>
  <li class="font-normal">
    <span class="font-normal"> to breach any legal duty owed to a third-party, including a contractual duty or a duty of confidence.</span>
  </li>
</ul>
<p><span class="font-normal">You also agree:</span></p>
<ul>
  <li class="font-normal">
    <span class="font-normal"
      >Not to access without authority, interfere with, damage or disrupt any part of the Portal or any hardware or software in relation to it.
    </span>
  </li>
  <li class="font-normal">
    <span class="font-normal"
      >You must not misuse the Portal by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically
      harmful.
    </span>
  </li>
  <li class="font-normal">
    <span class="font-normal"
      >not to access without authority, interfere with, damage or disrupt any part of the Portal or any hardware or software in relation to it;
    </span>
  </li>
  <li class="font-normal">
    <span class="font-normal"
      >you must not misuse the Portal by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically
      harmful;
    </span>
  </li>
  <li>
    <span class="font-normal"
      >you will not attempt to gain unauthorised access to the Portal, the server on which the Portal is stored, or any server, computer or database connected
      to the Portal; and
    </span>
  </li>
  <li>
    <span class="font-normal">you will not attack the Portal via a denial-of-service attack or a distributed denial-of service attack. </span>
  </li>
</ul>
<p>
  <strong class="block">Other terms relating to the Portal.</strong
  ><span class="font-normal"
    >Please note that these Portal Terms, their subject matter and their formation, are governed by the laws of Victoria, Australia if your Dental Care Provider
    is in Australia and the laws of New Zealand if your Dental Care Providers is in New Zealand or any other country. We may make changes to these Portal Terms
    and to the Portal from time to time and will post updated terms on the Portal. By continuing to use the Portal once the Portal Terms are updated, you agree
    to those updated Portal Terms. If you do not agree to the updated Portal Terms use must stop using the Portal.
  </span>
</p>
<p>
  <strong class="block">How to relay questions or comments regarding the Portal?</strong
  ><span class="font-normal">If you have any questions or comments regarding your use of the Portal, please contact your Dental Care Provider.</span>
</p>
<p>
  <strong class="block">Consumer Laws</strong
  ><span class="font-normal"
    >You may have the benefit of guarantees or other provisions implied or imposed by law in connection with your use of the Portal or these Portal Terms
    (including under the Australian Consumer Law or New Zealand Consumer Guarantees Act), the exclusion of which from a contract would contravene any statute or
    cause any part of these Portal Terms to be void (<strong>Non-excludable Conditions</strong>). These Portal Terms are subject to any Non-Excludable
    Conditions that apply to you.</span
  >
</p>

<p>
  <strong class="block">Limited Warranty and Liability</strong
  ><span class="font-normal"
    >We will make reasonable commercial efforts to make the Portal available and to ensure it operates as described. Other than the foregoing warranty and
    subject to Non-excludable Conditions, to the maximum extent permitted by law, Portal Provider provides the Portal and related services on an "as is, as
    available" basis without any warranties, representations, or guarantees of any kind including that the Portal will be uninterrupted or error free or that it
    is fit for a particular purpose.</span
  >
</p>
<p>
  Subject to Non-Excludable Conditions and to the extent permitted by law, the Portal Provider is not liable under the law of tort, contract or otherwise for
  any direct or indirect loss or damage, personal injury, loss of income, profits, data or savings or for any, incidental, consequential, exemplary, punitive or
  special loss or damage of any party, however caused, arising out of or in connection with the use of the Portal or in connection with these Portal Terms.
</p>

<p>
  <strong class="block">Termination by Portal Provider</strong
  ><span class="font-normal">Portal Provider may terminate your access to the Portal (either in whole or in part) for any reason including:</span>
</p>
<ul>
  <li>
    <span class="font-normal"> if you breach any of these Portal Terms; </span>
  </li>
  <li>
    <span class="font-normal">
      if the Portal Provider's agreement with the Dental Care Provider is terminated or any fees payable for the use of the Portal have not been paid by the
      Dental Care Provider responsible for the payment of the fees;
    </span>
  </li>
  <li>
    <span class="font-normal">
      if the Portal Provider considers it reasonably necessary to protect the Portal, the Portal Provider's systems or any other third party using the Portal;
      or
    </span>
  </li>
  <li>
    <span class="font-normal"> the Portal Provider ceases to provide the Portal services generally. </span>
  </li>
</ul>

<p>
  <span class="font-normal"
    >If your access to the Portal is terminated, please contact your Dental Care Provider to provide information requested by the Dental Care Provider or to
    access to you records.</span
  >
</p>

<p>
  <strong class="block">Termination by you</strong
  ><span class="font-normal"
    >You may terminate these Portal Terms at any time by ceasing to use the Portal and requesting that your dental care provider restricts information being
    processed on the Portal.</span
  >
</p>

<p>
  <strong class="block">Last updated February 2022</strong>
</p>
