<dentr-modal>
  <div *ngIf="!cancellation_info">
    <h2 class="heading-xl mb-4">Unable to cancel</h2>
    <p class="mb-4">You can cancel online up to 48 hours before your appointment. If you wish to discuss your appointment, please call us.</p>
    <div class="grid grid-cols-6 gap-3">
      <div class="col-span-6 sm:col-span-2" [ngClass]="{ 'justify-self-end sm:col-span-6': !sitePhoneNumber }">
        <button class="btn-secondary w-full sm:w-auto" (click)="close()">Close</button>
      </div>
      <div class="col-span-6 flex sm:col-span-4 sm:justify-self-end" *ngIf="sitePhoneNumber">
        <a class="btn-primary w-full no-underline sm:w-auto" href="tel:{{ sitePhoneNumber }}">Call us</a>
      </div>
    </div>
  </div>

  <div *ngIf="cancellation_info">
    <ng-template #multiBookingWarning>
      <div *ngIf="multiBookingOnSameDayWarning" class="flex items-center">
        <span class="mr-2 text-[1.25rem]">
          <ng-icon name="heroExclamationCircleSolid" class="text-amber-500"></ng-icon>
        </span>
        <p>
          This will only cancel the appointment at
          {{ selectedAppointment.start_time | practiceTime : "HH:mm" }} and not the other appointment on the same day at
          {{ multiBookingOnSameDayWarning.start_time | practiceTime : "HH:mm" }}. You will be able to cancel that as well if required, once this has been
          cancelled.
        </p>
      </div>
    </ng-template>

    <ng-template #dateTime>
      <p>{{ selectedAppointment.start_time | practiceTime : "ddd Do MMM YYYY" }} at {{ selectedAppointment.start_time | practiceTime : "HH:mm" }}</p>
    </ng-template>

    <div *ngIf="showCard === 'DEFAULT'">
      <div *ngIf="cancellation_info.cancellation_screen === 'PAY_TO_CANCEL'">
        <h2 class="heading-xl mb-4">Cancel appointment</h2>
        <ng-container *ngTemplateOutlet="dateTime"></ng-container>
        <p><strong class="font-semibold">Cancellation fee</strong></p>
        <p>
          <span *ngIf="cancellation_info.charge_if_less_than_hours && cancellation_info.charge_if_less_than_hours <= 72">
            Cancelling within
            {{ cancellation_info.charge_if_less_than_hours }} hours of this appointment
          </span>
          <span *ngIf="!cancellation_info.charge_if_less_than_hours || cancellation_info.charge_if_less_than_hours > 72">
            Cancelling this appointment after
            {{ selectedAppointment.start_time | practiceTime : "HH:mm" }} on
            {{ cancellation_info.can_cancel_before | practiceTime : "ddd Do MMM YYYY" }}
          </span>
          <span
            >incurs a fee of <strong>{{ cancellation_info.amount / 100 | practiceCurrency }}</strong
            >.
          </span>
        </p>
        <p>This can be paid online now to cancel the appointment.</p>
        <p>This fee is also chargeable for non attendance.</p>
        <ng-container *ngTemplateOutlet="multiBookingWarning"></ng-container>
        <div class="grid grid-cols-6 gap-3">
          <div class="col-span-6 sm:col-span-2">
            <button class="btn-secondary w-full sm:w-auto" (click)="close()">Close</button>
          </div>
          <div class="col-span-6 sm:col-span-4 sm:justify-self-end">
            <button class="btn-primary w-full sm:w-auto" (click)="cancelAppointment()">Pay &amp; Cancel</button>
          </div>
        </div>
      </div>

      <div *ngIf="cancellation_info.cancellation_screen === 'CONTACT_TO_CANCEL'">
        <h2 class="heading-xl mb-4">Unable to cancel</h2>
        <p class="mb-4">You can cancel online up to 48 hours before your appointment. If you wish to discuss your appointment, please call us.</p>
        <div class="grid grid-cols-6 gap-3">
          <div class="col-span-6 sm:col-span-2" [ngClass]="{ 'justify-self-end sm:col-span-6': !sitePhoneNumber }">
            <button class="btn-secondary w-full sm:w-auto" (click)="close()">Close</button>
          </div>
          <div class="col-span-6 flex sm:col-span-4 sm:justify-self-end" *ngIf="sitePhoneNumber">
            <a class="btn-primary w-full no-underline sm:w-auto" href="tel:{{ sitePhoneNumber }}">Call us</a>
          </div>
        </div>
      </div>

      <div *ngIf="cancellation_info.cancellation_screen === 'CANCEL_NO_FEE'">
        <h2 class="heading-xl mb-4">Are you sure?</h2>
        <p>Please confirm you want to cancel this appointment.</p>
        <p class="mb-2">If you paid a deposit for this appointment, you'll need to call us to request a refund.</p>
        <ng-container *ngTemplateOutlet="multiBookingWarning"></ng-container>

        <div class="mt-4 grid grid-cols-6 gap-3">
          <div class="col-span-6 sm:col-span-2">
            <button class="btn-secondary w-full sm:w-auto" (click)="close()">Close</button>
          </div>
          <div class="col-span-6 sm:col-span-4 sm:justify-self-end">
            <button class="btn-primary w-full sm:w-auto" (click)="cancelAppointment()">Cancel Appointment</button>
          </div>
        </div>
      </div>
    </div>

    <dentr-loading *ngIf="showCard === 'LOADING'"></dentr-loading>

    <div *ngIf="showCard === 'CANCELLING_DONE'">
      <h2 class="heading-xl mb-4">Appointment cancelled</h2>
      <p>You can reschedule your appointment at a better time.</p>
      <div class="mt-4 grid grid-cols-6 gap-3">
        <div class="col-span-6 sm:col-span-2">
          <button class="btn-secondary w-full sm:w-auto" (click)="close()">Close</button>
        </div>
        <div class="col-span-6 sm:col-span-4 sm:justify-self-end">
          <button class="btn-primary w-full sm:w-auto" (click)="rebookAppointment()" *ngIf="bookableApptsService.canBook">Rebook now</button>
        </div>
      </div>
    </div>
    <div *ngIf="showCard === 'ERROR'">
      <h2 class="flex items-center text-base">
        <span class="mr-2 text-[1.25rem]">
          <ng-icon name="heroExclamationCircleSolid" class="text-red-700"></ng-icon>
        </span>
        Error
      </h2>
      <p>Something went wrong</p>
      <div class="flex justify-end">
        <button class="btn-secondary w-full sm:w-auto" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</dentr-modal>
