import { PatientMedicalHistoryQuestionBase } from "./medical-history-questions-base";

export class PatientMedicalHistoryBase {
  id: number;
  created_at: string;
  questions: Array<PatientMedicalHistoryQuestionBase>;
  has_yes_answers: boolean; // Do not set a default or task reminder links won't offer login when they should
  patient_notes: string;
  is_feature_enabled: boolean;
  status: {
    valid: boolean;
    updated_at?: string;
    expired: boolean | null;
    expire_days: number;
  };
  signature_data: string | null;
  site_id: string;
  start_time: string;
  appointment_id: string;
  updated_at?: string;

  constructor() {
    this.patient_notes = "";
    this.questions = [];
    this.is_feature_enabled = false;
    this.status = {
      valid: false,
      expired: null,
      expire_days: 0,
    };
  }
}
