import { Injectable } from "@angular/core";
import { ThemeService } from "./theme.service";
import { BrandInfoEntry, fromBrandInfoBase } from "src/app/data_model/brand-info";
import { PatientsService } from "./patients.service";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { Constants } from "src/constants";
import { JWTService } from "./jwt.service";
import { CacheService } from "./cache.service";
import merge from "lodash/merge";
import { PatientEntry } from "src/app/data_model/patient";
import { BrandInfoBase } from "@backend/graph/brand_info/brand-info-base";
import { UrlSiteAliasBase } from "@backend/graph/url_site_aliases/url-site-alias-base";
import { PwaService } from "./pwa.service";

export type T_BrandThemeOverride = Pick<UrlSiteAliasBase, "colour" | "logo_url" | "favicon_preview_url" | "owned_brand_id"> | null;

export type RestrictedSiteInfo = {
  site_id: string;
  site_name: string;
  colour: string;
  logo_url: string;

  //these never actually seem to be set - raised an issue for Jamie to look at as
  //he added this code
  owned_brand_id?: string;
  favicon_preview_url?: string;
};

@Injectable({
  providedIn: "root",
})
export class BrandService {
  private _brand: BrandInfoEntry;
  private _isPip: boolean;

  constructor(
    private _themeService: ThemeService,
    private _patientService: PatientsService,
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _cacheService: CacheService,
    private _pwaService: PwaService
  ) {
    this._patientService.onPatientInfo.subscribe(async (patientInfo: PatientEntry) => {
      const { portal_url_full } = patientInfo;

      if (portal_url_full?.includes("/practices/")) {
        const alias = portal_url_full.substring(portal_url_full.lastIndexOf("/") + 1);
        await this.setRestrictedSiteIdFromAlias(alias);
      }
    });
  }

  public set brand(brand: BrandInfoEntry) {
    // Need to merge rather than replace as we have two different brand_info objects
    if (this._brand) {
      merge(this._brand, brand);
    } else {
      this._brand = brand;
    }
    const { _themeOverride: themeOverride } = this;
    if (themeOverride?.colour) this.brand.colour = themeOverride.colour;
    if (themeOverride?.logo_url) this.brand.logo_url = themeOverride.logo_url;
    if (themeOverride?.favicon_preview_url) this.brand.favicon_preview_url = themeOverride.favicon_preview_url;
    this._themeService.setup(this.brand.colour);
    window.document.title = this.brand.display_name;

    this._pwaService.setupPwa(this.brand, this._themeOverride);
  }

  public get brand(): BrandInfoEntry {
    return this._brand;
  }

  public get restrictedSiteId(): string {
    return this.restrictedSite?.site_id ?? "";
  }

  public set restrictedSiteId(siteId: string) {
    const site = this._brand.sites.find((s) => s.site_id === siteId);

    if (!site) return;

    this.restrictedSite = {
      site_id: siteId,
      site_name: site.site_name,
      colour: this._brand.colour,
      logo_url: this._brand.logo_url,
    };
  }

  public get restrictedSiteName(): string {
    return this.restrictedSite?.site_name ?? "";
  }

  private get _themeOverride(): T_BrandThemeOverride {
    const aliasInfo = this.restrictedSite;
    if (!aliasInfo) return null;
    return {
      colour: aliasInfo.colour,
      logo_url: aliasInfo.logo_url,
      favicon_preview_url: aliasInfo.favicon_preview_url,
      owned_brand_id: aliasInfo.owned_brand_id,
    };
  }

  /**
   * When we pair the device and the new tab opens, the jwt won't be set to a PiP jwt yet,
   * so we set the isPip property in this class as a workaround
   */
  public get isPip(): boolean {
    return this._isPip || this._jwtService.isPip();
  }

  public set isPip(isPip: boolean) {
    this._isPip = isPip;
  }

  public get restrictedSite(): RestrictedSiteInfo | null {
    const data = this.isPip
      ? this._cacheService.get(Constants.RESTRICTED_SITE_STORAGE_KEY)
      : this._cacheService.getSession(Constants.RESTRICTED_SITE_STORAGE_KEY);
    if (data) return JSON.parse(data);
    return null;
  }

  public set restrictedSite(restrictedSiteInfo: RestrictedSiteInfo | null) {
    if (this.isPip) {
      this._cacheService.set(Constants.RESTRICTED_SITE_STORAGE_KEY, JSON.stringify(restrictedSiteInfo));
    } else {
      this._cacheService.setSession(Constants.RESTRICTED_SITE_STORAGE_KEY, JSON.stringify(restrictedSiteInfo));
    }
  }

  public deleteRestrictedSite(): void {
    this._cacheService.deleteSession(Constants.RESTRICTED_SITE_STORAGE_KEY);
    this._cacheService.delete(Constants.RESTRICTED_SITE_STORAGE_KEY);
  }

  public async setRestrictedSiteIdFromAlias(alias: string): Promise<boolean> {
    const response = await this._httpService
      .query<any>(
        GRAPHQL_OPERATION_NAMES.URL_SITE_ALIAS,
        `
      {
        practice {
          url_site_alias(alias: "${alias}") {
            site_id
            site {
              name
            }
            colour
            logo_url
          }
        }
      }
    `
      )
      .pipe()
      .toPromise();

    const urlSiteAlias = response.data?.practice?.url_site_alias;
    const site_id = urlSiteAlias?.site_id || "";
    const site_name = urlSiteAlias?.site?.name || "";
    const colour = urlSiteAlias?.colour || "";
    const logo_url = urlSiteAlias?.logo_url || "";

    if (site_id && site_name) {
      this.restrictedSite = {
        site_id,
        site_name,
        colour,
        logo_url,
      };

      this.brand.colour = colour;
      this.brand.logo_url = logo_url;
      this._themeService.setup(this.brand.colour);

      return true;
    }

    return false;
  }

  public setupBrandingConfig(brand: BrandInfoBase): boolean {
    if (brand) {
      this.brand = fromBrandInfoBase(brand, this._patientService.patientInfo);
      return true;
    }
    return false;
  }
}
