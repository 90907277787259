import { Subject, debounceTime } from "rxjs";
import { CacheService } from "../services/cache.service";

export class PersistentArrayFactory {
  private _onPropertyChange = new Subject<Array<any>>();

  constructor(private _cacheService: CacheService, private _cacheKey: string) {
    this._onPropertyChange.pipe(debounceTime(10)).subscribe((array) => {
      this._cacheService.setJsonSession(this._cacheKey, array, Date.now() + 1000 * 60 * 60);
    });
  }

  public create<T>(array: Array<T>): Array<T> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const cache = this._cacheService.getJsonSession<Array<T> | undefined>(this._cacheKey);
    const data = (cache || array).map((item, index) => {
      return self._wrap(cache || array, index, item);
    });
    const proxy = new Proxy<Array<T>>(data, {
      get(target: Array<any>, prop, receiver) {
        if (prop === "push") {
          return (item: any) => {
            item = self._wrap(target, target.length, item);

            self._onPropertyChange.next([...target, item]);

            return target[prop](item);
          };
        }

        return Reflect.get(target, prop, receiver);
      },
      set(target, property, newValue, receiver) {
        if (property !== "length" && typeof property === "string" && !isNaN(parseInt(property, 10))) {
          target[property] = self._wrap(target, parseInt(property, 10), newValue);

          self._onPropertyChange.next(target);
        }

        return Reflect.set(target, property, newValue, receiver);
      },
    });

    return proxy;
  }

  public clear(array: Array<any>): void {
    array.length = 0;

    this._cacheService.deleteSession(this._cacheKey);
  }

  private _wrap(array: Array<any>, index: number, data: any): any {
    if (typeof data !== "object" || data === null) return data;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return new Proxy(data, {
      set(target, property, value, receiver) {
        target[property] = value;
        array[index] = target;

        self._onPropertyChange.next(array);

        return Reflect.set(target, property, value, receiver);
      },
    });
  }
}
