import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import Bugsnag from "@bugsnag/js";
import { PipMetrics } from "@backend/common/analytics/pip-metrics";
import { AnalyticsService } from "../analytics.service";
import { BrandService } from "../brand.service";
import { PortalInPracticeService } from "../portal-in-practice.service";

@Injectable({
  providedIn: "root",
})
export class PipMetricsService {
  private _siteId: string | undefined;
  private _properties: PipMetrics.MetricProperties | null;

  constructor(
    private _analyticsService: AnalyticsService,
    private _brandService: BrandService,
    private _deviceDetectorService: DeviceDetectorService,
    private _pipService: PortalInPracticeService
  ) {}

  public trackDevicePaired(siteId: string): void {
    this._siteId = siteId;
    this._track("Device Paired");
  }

  public trackCheckInStarted(): void {
    this._track("Patient Check in Started");
  }

  public trackCheckInDobEntered(): void {
    this._track("Patient DOB Submitted");
  }

  public trackCheckInDaySelected(): void {
    this._track("Patient Check in Day Selected");
  }

  public trackCheckInMonthSelected(): void {
    this._track("Patient Check in Month Selected");
  }

  public trackCheckInYearEntered(): void {
    this._track("Patient Check in Year Entered");
  }

  public trackCheckInSurnameEntered(): void {
    this._track("Patient Check in Surname Entered");
  }

  public trackCheckInPatientNotFound(): void {
    this._track("Patient Check in Patient Not Found");
  }

  public trackCheckInFirstNameEntered(): void {
    this._track("Patient Check in First Name Entered");
  }

  public trackCheckInDeviceLocked(): void {
    this._track("Device Locked");
  }

  public trackCheckInDeviceUnlocked(): void {
    this._track("Device Unlocked");
  }

  public trackCheckedIn(): void {
    this._track("Patient Checked in");
  }

  private _generateProperties(): PipMetrics.MetricProperties {
    const properties = new PipMetrics.MetricProperties();

    const deviceInfo = this._deviceDetectorService.getDeviceInfo();
    properties.os = deviceInfo.os;
    properties.deviceName = deviceInfo.device;
    properties.browser = deviceInfo.browser;
    properties.os_version = deviceInfo.os_version;
    properties.browser_version = deviceInfo.browser_version;

    properties.deviceId = this._pipService.deviceId;
    properties.mode = this._pipService.serviceMode;

    return properties;
  }

  private _errorAndNotifyBugsnag(message: string, e: Error) {
    Bugsnag.notify(message);
    console.error(message, e);
  }

  private _track(metricName: string, additionalProperties?: Record<string, any>): void {
    try {
      if (!this._properties) this._properties = this._generateProperties();
      this._analyticsService.track(
        new PipMetrics.PipMetric(metricName, this._siteId ?? this._brandService.restrictedSiteId, this._properties, additionalProperties)
      );
    } catch (error) {
      this._errorAndNotifyBugsnag(`error tracking ${metricName} metric`, error);
    }
  }
}
