import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { CacheService } from "../services/cache.service";
import Bugsnag from "@bugsnag/js";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _cacheService: CacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this._isDentrDomain(req.url)) {
      const message = "Request to dentr domain";

      Bugsnag.leaveBreadcrumb(message, { url: req.url });
      Bugsnag.notify(new Error(message));
    }

    const jwt = this._cacheService.get("jwt");
    // If we have a JWT pass on the request with the Authorization header.
    if (jwt) {
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      return next.handle(authReq);
      // If we dont have a JWT and the request is for the app init domains call then pass it on to the next handler.
    } else if (req.url.includes("/api/domains/info")) {
      return next.handle(req);
    }

    return next.handle(req);
  }

  private _isDentrDomain(url: string): boolean {
    return /^(https?:\/\/)?[^\/]+\.dentr\.(io|net)/.test(url);
  }
}
