import forOwn from "lodash/forOwn";
import {
  BookableAppointmentPractitionerBase,
  BookableAppointmentBase,
  BookableAppointmentSiteBase,
  BookableAppointmentCategoryBase,
} from "../../../../../backend/src/graph/bookable_appointments/bookable-appointment-base";
import { ANY_PRACTITIONER_ID } from "@shared/constants";

import { intToFloat } from "../shared/utils/currency";
import { SortPractitioners } from "../shared/utils/sort-practitioners";
import { AvailableOptionEntry } from "./availability";
import dayjs, { Dayjs } from "dayjs";

export class BookableAppointmentPractitionerEntry extends BookableAppointmentPractitionerBase {
  public bg_class: string;
  public text_class: string;

  constructor(practitioner?: BookableAppointmentPractitionerBase) {
    super();

    if (practitioner) {
      forOwn(practitioner, (value, key) => {
        this[key] = value;
      });
    }
  }

  public get full_name(): string {
    return `${this.first_name} ${this.last_name ?? ""}`;
  }
}

export class BookableAppointmentEntry extends BookableAppointmentBase {
  private _selectedPractitioner: BookableAppointmentPractitionerEntry;
  private _startTime: Dayjs | null;

  public practitioners: Array<BookableAppointmentPractitionerEntry>;
  public sites: Array<BookableAppointmentSiteEntry>;

  public get reason(): string {
    return this.appointment_type_reason;
  }

  public get isNhs(): boolean {
    return this.payment_provider_id?.toLowerCase().includes("nhs");
  }

  public get plan_discount_display(): string {
    if (!this.plan_discount) return "0%";
    return `${Math.trunc(this.plan_discount * 100)}%`;
  }

  public get selected_practitioner(): BookableAppointmentPractitionerEntry {
    return this._selectedPractitioner;
  }

  public set selected_practitioner(value: BookableAppointmentPractitionerEntry) {
    this._selectedPractitioner = value;
  }

  private _generateAnyPractitionerEntry(): BookableAppointmentPractitionerEntry {
    const practitioner = new BookableAppointmentPractitionerEntry();
    practitioner.id = ANY_PRACTITIONER_ID;
    practitioner.first_name = "Any practitioner";
    practitioner.is_preferred = false;
    return practitioner;
  }

  public static create(data: BookableAppointmentBase | BookableAppointmentEntry): BookableAppointmentEntry {
    if (data instanceof BookableAppointmentEntry) return data;

    return new BookableAppointmentEntry(data);
  }

  constructor(appointment?: BookableAppointmentBase) {
    super();

    if (appointment) {
      forOwn(appointment, (value, key) => {
        if (key === "total_deposit" || key === "total_price") return;
        this[key] = value;
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    this.practitioners = new Array<BookableAppointmentPractitionerEntry>();
    this.selected_practitioner = new BookableAppointmentPractitionerEntry();
    this.sites = new Array<BookableAppointmentSiteEntry>();
    if (appointment) {
      for (const practitioner of appointment.practitioners) {
        this.practitioners.push(new BookableAppointmentPractitionerEntry(practitioner));
      }
      SortPractitioners(this.practitioners);
      if (this.practitioners.length) {
        const found_any_practitioner = this.practitioners.find((item) => item.id === ANY_PRACTITIONER_ID);
        if (!found_any_practitioner && this.practitioners.length > 1) this.practitioners.unshift(this._generateAnyPractitionerEntry());
        const preferred_practitioner = this.practitioners.find((item) => item.is_preferred);
        if (preferred_practitioner) {
          this.selected_practitioner = preferred_practitioner;
        } else {
          this.selected_practitioner = this.practitioners[0];
        }
      }
    }
  }

  public get has_allowances(): boolean {
    return this.plan_allowance_amount !== null;
  }

  public get totalDepositDisplay(): number | null {
    const { total_deposit } = this;
    if (!total_deposit) return null;
    return intToFloat(this.total_deposit);
  }

  public get totalPriceDisplay(): number | null {
    const { total_price } = this;
    if (total_price === null) return null;
    if (!total_price) return 0;
    return intToFloat(total_price);
  }

  public get start_time(): Dayjs | null {
    if (!this._startTime) return dayjs();
    return this._startTime;
  }

  public set start_time(value: Dayjs | null) {
    this._startTime = value;
  }
}

export class BookableAppointmentCategoryEntry extends BookableAppointmentCategoryBase {
  public site_appointment_types: Array<BookableAppointmentEntry>;

  constructor(category?: BookableAppointmentCategoryBase) {
    super();

    if (category) {
      forOwn(category, (value, key) => {
        this[key] = value;
      });
    }

    this.site_appointment_types = new Array<BookableAppointmentEntry>();

    if (category && category.site_appointment_types) {
      for (const appointment of category.site_appointment_types) {
        this.site_appointment_types.push(new BookableAppointmentEntry(appointment));
      }
    }
  }

  public get has_nhs_appointments(): boolean {
    return this.site_appointment_types.some((item) => item.isNhs);
  }
}

export class BookableAppointmentSiteEntry extends BookableAppointmentSiteBase {
  public appointment_categories: Array<BookableAppointmentCategoryEntry>;
  public hidden = false;

  constructor(site?: BookableAppointmentSiteBase) {
    super();

    if (site) {
      forOwn(site, (value, key) => {
        this[key] = value;
      });
    }
    this.appointment_categories = new Array<BookableAppointmentCategoryEntry>();

    if (site && site.appointment_categories) {
      for (const category of site.appointment_categories) {
        this.appointment_categories.push(new BookableAppointmentCategoryEntry(category));
      }
    }
  }
}

export class BookableAppointmentSlotEntry {
  public appointment_type_id: string;
  public site_name: string;
  public site_id: string;
  public name: string;
  public time: string;
  public selected_option_index: number;
  public options: Array<AvailableOptionEntry>;

  constructor(slot?: BookableAppointmentSlotEntry) {
    if (slot) {
      forOwn(slot, (value, key) => {
        if (key === "options") {
          this.options = new Array<AvailableOptionEntry>();

          for (const option of slot.options) {
            this.options.push(new AvailableOptionEntry(option));
          }
        } else {
          this[key] = value;
        }
      });
    }
  }
}
