import forOwn from "lodash/forOwn";
import { AvailabilityBase, AvailableOptionBase, AvailableTimeSlotBase, AvailableIndividuallyBase } from "@backend/graph/availability/availability-base";

export class AvailableOptionEntry extends AvailableOptionBase {
  constructor(baseItem?: AvailableOptionBase) {
    super();

    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      if (key === "total_score") return;

      this[key] = value;
    });
  }
}

export class AvailabilityEntry extends AvailabilityBase {
  public date_range = new Array<string>();
  public show_all_slots = false;
  constructor(baseItem?: AvailabilityBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}

export class AvailabilityTimeSlotEntry extends AvailableTimeSlotBase {}

export class AvailableIndividuallyEntry extends AvailableIndividuallyBase {}
