enum customJsonTypes {
  DATE = "instanceof-Date",
}

function replacer(this: any, key: string, _value: any) {
  if (this[key] instanceof Date) {
    return {
      value: this[key].toISOString(),
      type: customJsonTypes.DATE,
    };
  }
  return this[key];
}

function reviver(this: any, _key: string, value: any) {
  if (
    value !== null &&
    typeof value === "object" &&
    Object.keys(value).length === 2 &&
    Object.keys(value).includes("type") &&
    Object.keys(value).includes("value") &&
    value.type === customJsonTypes.DATE
  ) {
    return new Date(value.value);
  }

  return value;
}

export const JsonUtils = {
  parse<T>(json: string): T {
    return JSON.parse(json, reviver);
  },

  stringify<T>(obj: T): string {
    return JSON.stringify(obj, replacer);
  },
};

export function toUnquotedJSON(param) {
  if (Array.isArray(param)) {
    const results = new Array<any>();

    for (const elem of param) {
      results.push(toUnquotedJSON(elem));
    }

    return "[" + results.join(",") + "]";
  }

  if (param && typeof param === "object") {
    const props = Object.keys(param)
      .map((key) => {
        let safeKey = key;

        if (key.match(/^[a-zA-Z_$][a-zA-Z\d_$]*$/) === null) safeKey = `"${key}"`;

        return `${safeKey}: ${toUnquotedJSON(param[key])}`;
      })
      .join(",");

    return `{${props}}`;
  }

  return JSON.stringify(param);
}
