import { Injectable } from "@angular/core";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { PatientShortCodeBase } from "../../../../../../backend/src/graph/patient-short-codes/patient-short-code-base";
import { Observable, lastValueFrom, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { JWTService } from "./jwt.service";
import { AccountsService } from "./accounts.service";
import { LocationService } from "./location.service";
import { CacheService } from "./cache.service";
import { Constants } from "src/constants";

interface I_ShortCodeResponse {
  errors?: Array<any>;
  data: {
    patient_short_code: PatientShortCodeBase;
  };
}

@Injectable({
  providedIn: "root",
})
export class ShortCodesService {
  constructor(
    private _http: HttpService,
    private _jwtService: JWTService,
    private _accountsService: AccountsService,
    private _locationService: LocationService,
    private _cacheService: CacheService
  ) {}

  public get short_code_id(): string | null {
    return this._cacheService.getSession(Constants.SHORT_CODE_ID_STORAGE_KEY);
  }

  public set short_code_id(short_code_id: string | null) {
    if (!short_code_id) {
      this._cacheService.deleteSession(Constants.SHORT_CODE_ID_STORAGE_KEY);
      return;
    }
    this._cacheService.setSession(Constants.SHORT_CODE_ID_STORAGE_KEY, short_code_id);
  }

  public getShortCode(short_code: string): Observable<I_ShortCodeResponse> {
    const query = `{
      patient_short_code(id: "${short_code}") {
        id
        path
        data
        jwt
        referrer
      }
    }`;

    return this._http.query<PatientShortCodeBase>(GRAPHQL_OPERATION_NAMES.SHORT_CODE, query).pipe(catchError((error) => of({ errors: [error], data: null })));
  }

  public deleteShortCode(short_code: string): Observable<any> {
    const query = `{
      deletePatientShortCode(id: "${short_code}")
    }`;

    return this._http.mutation("deletePatientShortCode", query);
  }

  public createShortCode(path: string): Observable<string> {
    const jwt = this._jwtService.getJWT();
    let mutation = `{
      createPatientShortCode(
        new_item: {
          path: "${path}"`;

    if (jwt.patient_id) {
      mutation += `
          patient_id: "${jwt.patient_id}"`;
    }

    mutation += `
          restricted_mode: ${this._jwtService.isRestricted}
          hostname: "${this._locationService.hostname}"
        }
      ) {
        id
      }
    }`;

    return this._http
      .mutation<{ data?: { createPatientShortCode: { id: string } } }>("createPatientShortCode", mutation)
      .pipe(map((response) => response?.data?.createPatientShortCode?.id));
  }

  /**
   * Deletes the short code so that it can't be used anymore and signs the patient out
   */
  public async actionComplete(): Promise<void> {
    this.short_code_id = null;
    await lastValueFrom(this.deleteShortCode(this._jwtService.getJWT("sid")));
    this._accountsService.forcedAmountToPay = null;
  }
}
