import forOwn from "lodash/forOwn";
import { BrandInfoBase, BrandInfoSiteBase, BrandInfoSiteSettingsBase } from "../../../../../backend/src/graph/brand_info/brand-info-base";
import { PatientEntry } from "./patient";

export type BrandInfoFeaturesEntry = {
  forms__nhs_pr: boolean;
  forms__medical_history: boolean;
  forms__treamtent_plan_estimates: boolean;
  payments__balance_payments: boolean;
};

type extraInfo = {
  appointment_list_first: boolean;
  features: BrandInfoFeaturesEntry;
  ga_tracking_cookie_domain: string | null;
  ga_tracking_id: string | null;
  ga_tracking_link_domain: string | null;
  site_map: Map<string, BrandInfoSiteEntry>;
  expires: string;
};

export type BrandInfoEntry = BrandInfoBase & extraInfo;

export class BrandInfoSiteEntry implements BrandInfoSiteBase {
  public settings: BrandInfoSiteSettingsBase;
  public site_id: string;
  public site_name: string;
  public site_phone_number: string;
  public site_email_address: string;
  public site_website: string;
  public site_address_line_1: string;
  public site_address_line_2: string;
  public site_address_line_3: string;
  public site_town: string;
  public site_county: string;
  public site_postcode: string;
  public stripe_account_id: string;

  constructor(site?: BrandInfoSiteBase) {
    if (site) {
      forOwn(site, (value, key) => {
        this[key] = value;
      });
    }
  }

  public get site_address(): string {
    return [this.site_address_line_1, this.site_address_line_2, this.site_address_line_3, this.site_town, this.site_county, this.site_postcode]
      .filter((line) => !!line)
      .join("\n");
  }
}

export type BrandInfoSettingsEntry = BrandInfoSiteSettingsBase;

export function fromBrandInfoBase(brandInfo: BrandInfoBase, patient: PatientEntry | null): BrandInfoEntry {
  const site_map = new Map<string, BrandInfoSiteEntry>();
  let features: BrandInfoFeaturesEntry;

  if (brandInfo.sites) {
    // Sort the sites
    brandInfo.sites.sort((a, b) => {
      if (a.site_name.toLowerCase() < b.site_name.toLowerCase()) return -1;
      if (a.site_name.toLowerCase() > b.site_name.toLowerCase()) return 1;
      return 0;
    });

    for (const site of brandInfo.sites) {
      site_map.set(site.site_id, new BrandInfoSiteEntry(site));
    }

    const patient_site_id = patient?.site_id;

    features = {
      forms__nhs_pr: !!brandInfo.sites.find((site) => site.site_id === patient_site_id && site.settings.forms__nhs_pr),
      forms__medical_history: !!brandInfo.sites.find((site) => site.site_id === patient_site_id && site.settings.forms__medical_history),
      forms__treamtent_plan_estimates: !!brandInfo.sites.find((site) => site.site_id === patient_site_id && site.settings.forms__treamtent_plan_estimates),
      payments__balance_payments: !!brandInfo.sites.find((site) => site.site_id === patient_site_id && site.settings.payments__balance_payments),
    };
  } else {
    features = {
      forms__nhs_pr: false,
      forms__medical_history: false,
      forms__treamtent_plan_estimates: false,
      payments__balance_payments: false,
    };
  }
  return { ...brandInfo, site_map, features } as BrandInfoEntry;
}
