import { trigger, style, transition, animate, keyframes, query, stagger, AUTO_STYLE, state, animateChild, group } from "@angular/animations";

const isE2E = window.localStorage.getItem("e2e");
export const FAST_ANIMATION_DURATION = isE2E ? 0 : 150;
export const SLOW_ANIMATION_DURATION = isE2E ? 0 : 300;
export const STAGGER_ANIMATION_DURATION = isE2E ? 0 : 500;

const cubic_bezier = "cubic-bezier(0.645, 0.045, 0.355, 1.000)";

export const ACCORDION_ANIMATION = trigger("accordionAnimation", [
  transition(":enter", [style({ opacity: 0, height: 0 }), animate(`${FAST_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`, style({ opacity: 1, height: "*" }))]),
  transition(":leave", [animate(`${FAST_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`, style({ opacity: 0, height: 0 }))]),
]);

// Similar to accordion animation, but with a works with elements that are not hidden via *ngIf
export const COLLPASE_ANIMATION = trigger("collapseAnimation", [
  state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })),
  state("true", style({ height: "0", visibility: "hidden", opacity: 0 })),
  transition("false => true", animate(`${FAST_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`)),
  transition("true => false", animate(`${FAST_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`)),
]);

export const TOAST_TOP_ANIMATION = trigger("toastTopAnimation", [
  transition(":enter", [
    style({ transform: "translateY(-100%)" }),
    animate(`${SLOW_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`, style({ transform: "translateY(0%)" })),
  ]),
  transition(":leave", [animate(`${SLOW_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`, style({ transform: "translateY(-100%)" }))]),
]);

export const TOAST_BOTTOM_ANIMATION = trigger("toastBottomAnimation", [
  transition(":enter", [
    style({ transform: "translateY(100%)" }),
    animate(`${SLOW_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`, style({ transform: "translateY(0%)" })),
  ]),
  transition(":leave", [animate(`${SLOW_ANIMATION_DURATION}ms 0ms ${cubic_bezier}`, style({ transform: "translateY(100%)" }))]),
]);

export const STAGGER_APPEAR_ANIMATION = trigger("staggerAppearAnimation", [
  transition("* => *", [
    query(":enter", style({ opacity: 0, height: 0, overflow: "hidden" }), { optional: true }),

    query(
      ":enter",
      stagger(`${STAGGER_ANIMATION_DURATION}ms`, [
        animate(
          `${STAGGER_ANIMATION_DURATION}ms 0ms cubic-bezier(0.305, 0.005, 0.125, 1.000)`,
          keyframes([style({ opacity: 0, height: 0 }), style({ opacity: 1 }), style({ opacity: 1, height: "*" })])
        ),
      ]),
      { optional: true }
    ),
  ]),
]);

export const FADE_IN_ANIMATION = trigger("fadeInAnimation", [
  transition(":enter", [style({ opacity: 0 }), animate(`${FAST_ANIMATION_DURATION}ms 0ms ease-out`, style({ opacity: 1 }))]),
  transition(":leave", [animate(`${FAST_ANIMATION_DURATION}ms 0ms ease-out`, style({ opacity: 0 }))]),
]);

export const SCALE_ANIMATION = (from = 0) =>
  trigger("scaleAnimation", [
    transition(":enter", [style({ transform: `scale(${from})` }), animate(`${FAST_ANIMATION_DURATION}ms 0ms ease-out`, style({ transform: "scale(1)" }))]),
    transition(":leave", [animate(`${FAST_ANIMATION_DURATION}ms 0ms ease-in`, style({ transform: `scale(${from})` }))]),
  ]);

export function slideTo(direction) {
  return [
    query(":enter, :leave", [
      style({
        position: "absolute",
        top: 0,
        [direction]: 0,
        width: "100%",
        height: "fit-content",
      }),
    ]),
    query(":enter", [
      style({
        opacity: 0,
        [direction]: "-24px",
      }),
    ]),
    query(":leave", animateChild(), { optional: true }),
    group([
      query(":leave", [animate(`100ms cubic-bezier(0.7, 0, 1, 1)`, style({ opacity: 0 }))], {
        optional: true,
      }),
      query(":enter", [animate(`300ms 150ms cubic-bezier(0, 0, 0.3, 1)`, style({ [direction]: "0%", opacity: 1 }))]),
    ]),
  ];
}

export function routeFadeInOut() {
  return [
    query(":enter", [style({ opacity: 0, position: "absolute" })], {
      optional: true,
    }),
    query(":leave", [style({ opacity: 1, position: "absolute" })], {
      optional: true,
    }),
    query(":leave", [animate(FAST_ANIMATION_DURATION, style({ opacity: 0 }))], { optional: true }),
    query(":enter", [animate(FAST_ANIMATION_DURATION, style({ opacity: 1 }))], { optional: true }),
  ];
}

export const ROUTE_ANIMATIONS = trigger("routeAnimations", [
  transition(":increment", slideTo("right")),
  transition(":decrement", slideTo("left")),
  transition("* => *", routeFadeInOut()),
]);
