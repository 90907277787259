import { Injectable } from "@angular/core";
import { PatientActionsEntry } from "src/app/data_model/patient-actions";
import { CacheService } from "./cache.service";
import { I_PatientActions_SessionStorage } from "./patient-actions.service";
import { Constants } from "src/constants";

@Injectable({
  providedIn: "root",
})
/**
 * Extracted from PatientActionsService to avoid cyclic dependencies
 * Responsible for caching patient actions in session storage
 */
export class PatientActionsCacheService {
  constructor(private _cacheService: CacheService) { }

  public get isExpired(): boolean {
    return this._expiry <= Date.now();
  }

  private get _expiry(): number {
    const actions_storage = this._sessionItem;
    return actions_storage?.expiry || 0;
  }

  private get _sessionItem(): I_PatientActions_SessionStorage {
    const sessionItem = JSON.parse(this._cacheService.getSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY) || "{}");

    if (sessionItem?.actions) sessionItem.actions = sessionItem.actions?.map((a) => new PatientActionsEntry(a, a.skipped));
    return sessionItem;
  }

  public get patientActions(): Array<PatientActionsEntry> {
    const actions_storage = this._sessionItem;
    return actions_storage?.actions || [];
  }

  public set patientActions(actions: Array<PatientActionsEntry>) {
    const storage_item = {
      actions,
      expiry: new Date().getTime() + 60 * 60 * 1000, // 1 hour
    };

    // We add a custom expiry of 1 hour so that we don't have to fetch the patient actions again for an hour. We refetch again after an hour to check if anything has changeed externally
    this._cacheService.setSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY, JSON.stringify(storage_item));
  }
}
