import { Pipe, PipeTransform } from "@angular/core";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import { CommonService } from "../services/common.service";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

@Pipe({ name: "practiceTime", standalone: true })
export class PracticeTimePipe implements PipeTransform {
  constructor(private _commonService: CommonService) {}

  transform(value: string | Date, format: string): string {
    return dayjs.utc(value).tz(this._commonService.practice.time_zone).format(format);
  }
}
