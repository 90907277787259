import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";

import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { SubSink } from "subsink";
import { GAService } from "./ga.service";
import { NavigationService } from "./navigation.service";
import { switchMap } from "rxjs/operators";
import { E_MhAndPrFormMode } from "../enums/mh-and-pr-form-mode.enum";
import { toQuotedString } from "../utils/string";
import { MedicalHistoryEntry, MedicalHistoryQuestionsEntry } from "src/app/data_model/medical-history";
import { JWTService } from "./jwt.service";
import { toUnquotedJSON } from "@shared/json-utils";
import { LocalisationService } from "./localisation.service";

declare let window: Window;

@Injectable({
  providedIn: "root",
})
export class MedicalHistoryService implements OnDestroy {
  onMedicalHistoryChanged: BehaviorSubject<{
    status: string | null;
    medical_history?: MedicalHistoryEntry | null;
  }> = new BehaviorSubject({
    status: null,
    medical_history: null,
  });
  onMedicalStatusDueChanged: BehaviorSubject<any> = new BehaviorSubject(false);
  onMedicalHistoryGetQuestionsChanged: BehaviorSubject<{
    status: string | null;
    medical_history?: MedicalHistoryQuestionsEntry | null;
    msg?: string;
    error?: any;
  }> = new BehaviorSubject({
    status: null,
    medical_history: null,
    msg: "",
    error: "",
  });
  onModeChanged: BehaviorSubject<E_MhAndPrFormMode> = new BehaviorSubject(E_MhAndPrFormMode.FORM);
  cache: {
    medical_history?: MedicalHistoryEntry | null;
  } = {};
  status = "_BLANK_";
  status_is_due = "_BLANK_";
  practitionersCount = {
    dentist: 0,
    hygienist: 0,
  };

  public medicalHistoryQuestions: MedicalHistoryQuestionsEntry;
  private _subs = new SubSink();
  private _isFetching: boolean;
  private _mode: E_MhAndPrFormMode = E_MhAndPrFormMode.FORM;
  constructor(
    private _httpService: HttpService,
    private _gAService: GAService,
    private _navigationService: NavigationService,
    private _jwtService: JWTService,
    private _localisationService: LocalisationService
  ) {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * The MedicalHistory App Main Resolver
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  }

  hasAnsweredYes(): Observable<boolean> {
    return this._httpService
      .query(
        GRAPHQL_OPERATION_NAMES.MEDICAL_HISTORY_HAS_YES_ANSWERS,
        `{
        patient {
          medical_history {
            has_yes_answers
          }
        }
      }`
      )
      .pipe(
        switchMap((response) => {
          if (!response.data.patient.medical_history) return of(false);

          return of(response.data.patient.medical_history.has_yes_answers);
        })
      );
  }

  getLatestUpdatedAt(): Observable<Date | null> {
    return this._httpService
      .query(
        GRAPHQL_OPERATION_NAMES.MEDICAL_HISTORY_UPDATED_AT,
        `{
        patient {
          medical_history {
            updated_at
          }
        }
      }`
      )
      .pipe(
        switchMap((response) => {
          if (!response.data.patient.medical_history) return of(null);

          return of(new Date(response.data.patient.medical_history.updated_at));
        })
      );
  }

  public get mode(): E_MhAndPrFormMode {
    return this._mode;
  }

  public set mode(value: E_MhAndPrFormMode) {
    this._mode = value;
    this.onModeChanged.next(value);
  }

  getLatestMedicalHistory(force?: boolean): void {
    if (this._isFetching) return;
    if (this.status === "LOADING") return;
    this.status = "LOADING";
    this.onMedicalHistoryChanged.next({ status: "LOADING" });
    if (this.cache.medical_history && !force) {
      this.status = "LOADED";
      this.onMedicalHistoryChanged.next({
        status: "LOADED",
        medical_history: this.cache.medical_history,
      });
    } else {
      this._isFetching = true;
      this._subs.sink = this._httpService
        .query(
          GRAPHQL_OPERATION_NAMES.MEDICAL_HISTORY,
          `{
          patient {
            date_of_birth
            age
            medical_history {
              is_feature_enabled
              patient_notes
              questions {
                details
                follow_up_question
                position
                question_id
                text
                value
              }
              status {
                valid
                expire_days
                updated_at
              }
            }
          }
        }`
        )
        .subscribe(
          (response) => {
            if (response?.data) {
              const { patient } = response.data;
              const { medical_history } = patient;
              let entry: MedicalHistoryEntry | null = null;
              if (medical_history) entry = new MedicalHistoryEntry(medical_history, patient);
              this.status = "LOADED";

              this.onMedicalHistoryChanged.next({ status: "UPDATED" });
              const isDue = !medical_history?.status?.valid ?? false;
              this.onMedicalStatusDueChanged.next(isDue);
              this.onMedicalHistoryChanged.next({
                status: "LOADED",
                medical_history: entry,
              });
              this.cache.medical_history = entry;
            }

            this._isFetching = false;
          },
          () => {
            this._isFetching = false;
          }
        );
    }
  }

  getMedicalHistoryQuestions(): void {
    this.onMedicalHistoryGetQuestionsChanged.next({ status: "LOADING" });
    this._subs.sink = this._httpService
      .query(
        GRAPHQL_OPERATION_NAMES.MEDICAL_HISTORY_QUESTIONS,
        `{
      patient {
        date_of_birth
        medical_history_questions {
          questions {
            details
            follow_up_question
            position
            question_id
            text
            value
            can_change
            tooltips
          }
          patient_notes
        }
      }
    }`
      )
      .subscribe(
        (response) => {
          if (response === "NOT_LOGGEDIN") {
            window.location.href = window.location.pathname.substring(1).split("/")[0];
          }
          this._gAService.action("medicalhistory_questions_loaded_success");
          const medical_history_questions = response?.data?.patient?.medical_history_questions;

          let medical_history: MedicalHistoryQuestionsEntry | null = null;
          if (medical_history_questions) {
            medical_history = new MedicalHistoryQuestionsEntry(medical_history_questions);
            if (!medical_history.patient_notes) medical_history.patient_notes = "";
            medical_history.dateOfBirth = response?.data?.patient?.date_of_birth;
            this.medicalHistoryQuestions = medical_history;
          }

          this.onMedicalHistoryGetQuestionsChanged.next({
            status: "LOADED",
            medical_history,
          });
        },
        (err) => {
          if (err === "NOT_LOGGEDIN") {
            this._gAService.error("medicalhistory_questions_loaded_error_not_logged_in");
            window.location.href = window.location.pathname.substring(1).split("/")[0];
          }
          this._gAService.error("medicalhistory_questions_loaded_error");
        }
      );
  }

  saveMedicalHistory(): void {
    const { questions, patient_notes, dateOfBirth, encoded_signature, signature_used } = this.medicalHistoryQuestions;
    this.onMedicalHistoryGetQuestionsChanged.next({ status: "SAVING" });
    this._gAService.action("medicalhistory_saving");
    const updated_questions = questions.map((question) => question.toBase());
    const query = `{
      createPatientMedicalHistory(new_item: {
        patient_notes: ${toQuotedString(patient_notes)}
        questions: ${toUnquotedJSON(updated_questions)}
        dateOfBirth: "${dateOfBirth}"
        encoded_signature: "${encoded_signature}"
        signature_used: ${signature_used}
      })
    }`;
    this._subs.sink = this._httpService.mutation("createPatientMedicalHistory", query).subscribe(
      (response) => {
        if (response.errors?.length) {
          this._handleCreateError(JSON.parse(response.errors[0].message));
        } else {
          delete this.cache.medical_history;
          this.onMedicalHistoryGetQuestionsChanged.next({
            status: "UPDATED",
          });
          this.onMedicalHistoryGetQuestionsChanged.next({ status: "SAVED" });
          this._gAService.goal("medicalhistory_saved");
          this.onMedicalHistoryGetQuestionsChanged.next({
            status: "LOADING",
          }); // BUGFIX due to next page load seeing saving state

          this._navigationService.navigate(this._jwtService.isRestricted ? "/restricted/medical-history/medical-history-completed" : "/my-dental");
        }
      },
      (error) => {
        this._handleCreateError({ error });
      }
    );
  }

  private _handleCreateError(error) {
    let msg = "Error saving your medical history. Please try again.";

    if (error.error === "NOT_LOGGEDIN") {
      msg = "Error saving your medical history. You are not signed in. Refreshing...";
      setTimeout(() => {
        window.location.href = window.location.pathname.substring(1).split("/")[0];
      }, 4000);
    }

    this.onMedicalHistoryGetQuestionsChanged.next({
      status: "ERROR_SAVING",
      msg,
      error,
    });
  }

  public get allowLoginForMedicalHistory(): boolean {
    if (this._localisationService.preventLogin) return false;
    return true;
  }
}
