import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { ModalCancelAppointmentComponent } from "../modal-cancel-appointment/modal-cancel-appointment.component";
import { OverlayService } from "src/app/shared/services/overlay.service";
import dayjs from "dayjs";
import { BookableAppointmentEntry, BookableAppointmentPractitionerEntry } from "src/app/data_model/bookable-appointment";
import { CancellationInfoEntry } from "src/app/data_model/appointment";
import { PracticeTimePipe } from "src/app/shared/pipe/practice-time.pipe";
import { SHARED } from "src/app/shared/shared";
import { FormsModule } from "@angular/forms";
import { I_SelectedAppointmentSlot } from "src/app/bookable-appointments/availability/slot-basketless/slot.component";
import { PracticeCurrencyPipe } from "src/app/shared/pipe/practice-currency.pipe";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroClock, heroMapPin } from "@ng-icons/heroicons/outline";

export class AppointmentCardPractitionerEntry {
  public id: string;
  public first_name: string;
  public last_name: string;
  public image_url: string | null;
  public bg_class: string;
  public text_class: string;
  public role: string;
  public site_phone_number?: string;
}

export class AppointmentCardEntry {
  public practitioner: AppointmentCardPractitionerEntry;
  // Practitioners is an array of bookable appointment practitioners as an appointment card will only show this list on bookable card screens
  public practitioners: Array<BookableAppointmentPractitionerEntry>;
  public start_time: string;
  public duration: number;
  public reason: string;
  public site_name: string;
  public site_address_line_1: string;
  public site_address_line_2: string;
  public site_address_line_3: string;
  public site_county: string;
  public site_postcode: string;
  public id: string;
  public cancellation_info?: CancellationInfoEntry;
  public deposit?: number | null;
  public price?: number | null;

  public show_selected_practitioner: boolean;

  constructor(data?: Partial<AppointmentCardEntry>) {
    Object.assign(this, data);
  }

  public get addressString() {
    const { site_address_line_1, site_address_line_2, site_address_line_3, site_county, site_postcode } = this;
    return [site_address_line_1, site_address_line_2, site_address_line_3, site_county, site_postcode].filter((i) => i).join(", ");
  }

  public get startTime() {
    return dayjs(this.start_time).toISOString();
  }

  public addPrices(selectedAppointments: Array<BookableAppointmentEntry>): void {
    let appointment = selectedAppointments.find((appt) => appt.id === this.id);

    if (!appointment) return;

    appointment = BookableAppointmentEntry.create(appointment);

    this.deposit = appointment.total_deposit;
    this.price = appointment.total_price;
  }

  public static fromSelectedAppointmentSlot(item: I_SelectedAppointmentSlot, show_selected_practitioner: boolean): AppointmentCardEntry {
    const entry = new AppointmentCardEntry();

    entry.practitioner = new AppointmentCardPractitionerEntry();
    entry.id = item.site_appointment_type_id;
    entry.duration = item.duration;
    entry.site_name = item.site_name;
    entry.start_time = item.start_time.toISOString();
    entry.site_address_line_1 = item.site_address_line_1;
    entry.site_address_line_2 = item.site_address_line_2;
    entry.site_address_line_3 = item.site_address_line_3;
    entry.site_county = item.site_county;
    entry.site_postcode = item.site_postcode;
    entry.reason = item.reason;
    entry.practitioner.id = item.practitioner.id;
    entry.practitioner.first_name = item.practitioner.first_name;
    entry.practitioner.last_name = item.practitioner.last_name;
    entry.practitioner.bg_class = item.practitioner.bg_class;
    entry.practitioner.text_class = item.practitioner.text_class;
    entry.practitioner.image_url = item.practitioner.image_url;
    entry.practitioner.role = item.practitioner.role;

    if (!show_selected_practitioner) entry.practitioners = item.practitioners || new Array<BookableAppointmentPractitionerEntry>();

    return entry;
  }
}

export interface I_AppointmentCardPractitionerChanged {
  practitioner_id: string;
  site_appointment_type_id: string;
}

@Component({
  selector: "dentr-appointment-card",
  templateUrl: "./appointment-card.component.html",
  standalone: true,
  imports: [PracticeTimePipe, PracticeCurrencyPipe, NgIconComponent, SHARED, FormsModule],
  providers: [provideIcons({ heroClock, heroMapPin })],
})
export class AppointmentCardComponent {
  @Output() onPractitionerChanged = new EventEmitter<I_AppointmentCardPractitionerChanged>();
  @Input() public appointments: Array<AppointmentCardEntry>;
  @Input() public showCancelButton? = true;
  @Input() public showPricing? = true;

  constructor(private _commonService: CommonService, private _overlayService: OverlayService) {}

  public changePractitioner($event: Event, appointment: AppointmentCardEntry): void {
    const { value } = $event.target as HTMLSelectElement;
    const found_practitioner = appointment.practitioners.find((practitioner) => practitioner.id === value);
    if (found_practitioner) {
      appointment.practitioner = found_practitioner;
      this.onPractitionerChanged.emit({
        practitioner_id: found_practitioner.id,
        site_appointment_type_id: appointment.id,
      });
    }
  }

  public cancelAppointment(appointment) {
    this._overlayService.open({
      component: ModalCancelAppointmentComponent,
      data: {
        appointment,
      },
    });
  }

  public get canCancel(): boolean {
    return this._commonService.practice.appointment_settings.allow_appointment_cancellations;
  }

  public getAppointmentReason(reason: string) {
    switch (reason) {
      case "Other":
        return "Appointment";
      case "Exam":
        return "Dental Health Check";
      case "Scale & Polish":
        return "Dental Hygiene";
      case "Exam + Scale & Polish":
        return "Dental Health Check + Dental Hygiene";
      default:
        return reason; // use the reason from backend
    }
  }
}
