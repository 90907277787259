import { Component, OnInit } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { PipLoginService } from "src/app/shared/services/pip-login.service";
import { SHARED } from "src/app/shared/shared";

@Component({
  selector: "dentr-login-pip-inactivity-modal",
  templateUrl: "./pip-login-inactivity-modal.component.html",
  standalone: true,
  imports: [SHARED, ModalComponent],
})
export class PipLoginInactivityModalComponent implements OnInit {
  private readonly _COUNTER_VAL = 60;
  public countDown: Subscription;
  public counter = this._COUNTER_VAL;

  constructor(private _overlayService: OverlayService, private _pipLoginService: PipLoginService) {}

  ngOnInit(): void {
    this._enableTimer();
  }

  public close(): void {
    this._overlayService.close();
    this._disableTimer();
    this._pipLoginService.startInactivityMonitor();
  }

  private _disableTimer() {
    if (this.countDown) this.countDown.unsubscribe();
  }

  private _enableTimer() {
    this.counter = this._COUNTER_VAL;

    this.countDown = timer(0, 1000).subscribe((val) => {
      if (val === this._COUNTER_VAL) {
        this._disableTimer();
        void this._pipLoginService.logoutPatientInPractice();
        return;
      }
      this.counter -= 1;
    });
  }
}
