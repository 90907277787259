import { SHARED } from "src/app/shared/shared";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment.sst";
import { SubSink } from "subsink";
import { BrandService } from "../../services/brand.service";
import { JWTService } from "../../services/jwt.service";
import { NavigationService } from "../../services/navigation.service";
import { OverlayService } from "../../services/overlay.service";
import { LegalComponent } from "../terms/legal.component";
import { PrivacyPolicyDefaultComponent } from "../terms/privacy-policy-default/privacy-policy-default.component";

@Component({
  selector: "dentr-footer",
  templateUrl: "./footer.component.html",
  standalone: true,
  imports: [SHARED],
})
export class FooterComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  public app_version = environment.VERSION || "no-version";
  public privacy_policy_url: string | null = null;

  constructor(
    public jwtService: JWTService,
    private _overlayService: OverlayService,
    private _navigationService: NavigationService,
    private _brandService: BrandService
  ) {}

  ngOnInit() {
    this.privacy_policy_url = this._brandService.brand?.privacy_policy_url ? this._brandService.brand.privacy_policy_url : null;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public openPrivacyPolicy() {
    this._overlayService.open({
      component: PrivacyPolicyDefaultComponent,
      data: {
        has_close_button: true,
      },
    });
  }

  public openTerms() {
    this._overlayService.open({
      component: LegalComponent,
      data: {
        is_modal: true,
        has_close_button: true,
      },
    });
  }

  // Help is only currently configured for login. We will be adding a more generic help page across the app
  public goToHelp() {
    this._navigationService.navigate("/login/help", {
      skipLocationChange: true,
    });
  }
}
