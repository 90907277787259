import { Injectable } from "@angular/core";
import { NavigationService } from "../services/navigation.service";
import { JWTService } from "../services/jwt.service";

@Injectable({
  providedIn: "root",
})
export class JwtResolver {
  constructor(private _navigationService: NavigationService, private _jwtService: JWTService) {}

  public resolve(): boolean {
    if (this._jwtService.isRestricted) {
      this._navigationService.navigate("/restricted/access");
      return false;
    }

    const jwtData = this._jwtService.getJWT();

    if (jwtData && this._jwtService.isPatient()) {
      this._navigationService.navigate("/my-dental");
      return true;
    }

    if (jwtData && jwtData.practice_id) return jwtData;

    return false;
  }
}
