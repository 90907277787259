import { E_ExamTypes } from "../backend/src/common/enums/exam-types";
import { AppointmentSettingsBase } from "../backend/src/graph/appointment_settings/appointment-settings-base";
import { PatientBase } from "../backend/src/graph/patients/patient-base";
import dayjs from "dayjs";

export enum E_PatientStatus {
  new,
  existing,
  lapsed,
}

export class PatientStatus {
  private _dentalStatus: E_PatientStatus;
  private _hygieneStatus: E_PatientStatus;

  public get dental(): E_PatientStatus {
    return this._dentalStatus;
  }

  public get hygiene(): E_PatientStatus {
    return this._hygieneStatus;
  }

  /**
   * Lapsed hygiene is usually based on the last time a patient had a dental exam - not when they last had a hygiene exam.
   * But to facilitate hygiene-only practices, we allow practices to choose to use the last hygiene exam date instead.
   */
  private _getLastHygieneExamDate(patient: PatientBase, hygiene_lapsing_exam_types: E_ExamTypes) {
    switch (hygiene_lapsing_exam_types) {
      case E_ExamTypes.HYGIENE:
        return patient.last_scale_and_polish_date;
      case E_ExamTypes.DENTAL:
      default:
        return patient.last_exam_date;
    }
  }

  private _calculateStatus(lastDate: string | Date | null, lapsedDuration: number) {
    if (!lastDate) return E_PatientStatus.new;

    const lapsedDate = dayjs(lastDate).add(lapsedDuration, "month");

    if (lapsedDate.isBefore(dayjs(), "day")) {
      return E_PatientStatus.lapsed;
    } else {
      return E_PatientStatus.existing;
    }
  }

  public constructor(patient: PatientBase | null, settings: AppointmentSettingsBase) {
    if (!patient) {
      this._dentalStatus = E_PatientStatus.new;
      this._hygieneStatus = E_PatientStatus.new;
      return;
    }

    const lastHygieneExamDate = this._getLastHygieneExamDate(patient, settings.hygiene_lapsing_exam_types);
    this._dentalStatus = this._calculateStatus(patient.last_exam_date, settings.lapsed_dental_duration);
    this._hygieneStatus = this._calculateStatus(lastHygieneExamDate, settings.lapsed_hygiene_duration);
  }
}
