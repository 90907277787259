import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";

const STEPS = {};

@Injectable({
  providedIn: "root",
})
export class LoginRouteAnimationResolver {
  public resolve(route: ActivatedRouteSnapshot): number {
    const path = route.routeConfig?.path || "";

    if (!STEPS[path]) STEPS[path] = Object.keys(STEPS).length + 1;

    return STEPS[path];
  }
}
