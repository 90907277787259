import forOwn from "lodash/forOwn";
import { PatientAccountBase } from "../../../../../backend/src/apis/payments/patient-account-base";

export class PatientAccountEntry extends PatientAccountBase {
  constructor(baseItem?: PatientAccountBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
