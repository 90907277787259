import forOwn from "lodash/forOwn";
import { AcquisitionSourceBase } from "../../../../../backend/src/graph/acquisition_sources/acquisition-source-base";

export class AcquisitionSourceEntry extends AcquisitionSourceBase {
  constructor(baseItem?: AcquisitionSourceBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
