export namespace PusherNames {
  export namespace Patient {
    export function getStageSpecificChannelName(stage: string): string {
      return `private-${stage}-patient`;
    }

    export function getPrivateChannelName(channelName: string): string {
      return `private-${channelName}-patient`;
    }
  }
}
