export enum E_ManageFeatures {
  BLOCK_MULTIPLE_LOGINS = "block-multiple-manage-logins",
  CUSTOM_FAVICON = "custom-favicon",
  DEVICES = "devices",
  MULTISITE_EMAIL_ADDRESS = "multisite-email-address",
  ONLINE_SIGNING_AUTO_SWITCH_ON = "online-signing-auto-switch-on",
  L2_L1_Login = "l2-l1-login",
  LOGOUT_ON_INACTIVITY = "logout-on-inactivity-manage",
}

export enum E_PatientFeatures {
  AVAILABILITY_API_V2 = "availability-api-v2",
  BLOCK_MULTIPLE_LOGINS = "block-multiple-patient-logins",
  CAPTURE_INSURANCE_DETAILS = "capture-insurance-details",
  CHAT_BOT = "chat-bot",
  CONFIRM_APPOINTMENTS_AFTER_FORM_UPDATE = "confirm-appointments-after-form-update",
  EMAIL_SENDING_VIA_PMS = "email-sending-via-pms",
  EMERGENCY_CONTACT = "emergency-contact",
  GP17 = "gp17",
  MH_QUESTION_AI_ANALYSIS = "mh-question-ai-analysis",
  LOGOUT_ON_INACTIVITY = "logout-on-inactivity-patient",
  PATIENT_GP_DETAILS = "patient-gp-details",
  RECAPTCHA = "recaptcha",
  SLOT_VARIANTS = "slot-variants",
  SQL_QUERY_REDUCTION = "sql-query-reduction",
}

export type E_Features = E_ManageFeatures | E_PatientFeatures;

export enum E_SlotVariantFeature {
  FULL = "full",
  SINGLE = "single",
  MULTIPLE_SAME_DAY = "multiple-same-day",
  MULTIPLE_DIFFERENT_DAY = "multiple-different-day",
}
