import { E_PipServiceMode } from "@shared/constants";
import { I_Referrer } from "../interfaces/referrer";
import { Metric } from "./metric";

export namespace PipMetrics {
  export class MetricProperties {
    public referrer: I_Referrer;
    public os: string;
    public deviceName: string;
    public browser: string;
    public mode: E_PipServiceMode;
    public os_version: string;
    public browser_version: string;
    public deviceId: string | null;
  }

  export class PipMetric extends Metric {
    constructor(name: string, siteId: string, metricProperties: MetricProperties, additionalProperties: Record<string, any> = {}) {
      super(name, siteId, { ...metricProperties, ...additionalProperties });
    }
  }
}
