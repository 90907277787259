import { SHARED } from "src/app/shared/shared";
import { AfterViewInit, Component, ContentChild, ElementRef, ViewChild } from "@angular/core";
import { SubSink } from "subsink";
import { OverlayService } from "../../services/overlay.service";
import { A11yModule } from "@angular/cdk/a11y";
import { SCALE_ANIMATION, FAST_ANIMATION_DURATION } from "../../utils/animations";

@Component({
  selector: "dentr-modal",
  templateUrl: "modal.component.html",
  standalone: true,
  imports: [SHARED, A11yModule],
  animations: [SCALE_ANIMATION(0.9)],
})
export class ModalComponent implements AfterViewInit {
  @ContentChild("modalScroll") modalScroll: ElementRef;
  @ViewChild("modal") modal: ElementRef;

  private _subs = new SubSink();
  private _lastScrollTop = 0;
  private _resizeTimer: any;
  public scrollable_modal = false;
  public display = true;
  public scroll_end = false;

  constructor(private _overlayService: OverlayService) {}

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private get _modalElement(): HTMLElement {
    return <HTMLElement>this.modal?.nativeElement;
  }

  private get _modalScrollElement(): HTMLElement {
    return <HTMLElement>this.modalScroll?.nativeElement;
  }

  private get _modalBottomOffset(): number {
    return window.innerHeight - (this._modalElement.clientHeight + this._modalElement.getBoundingClientRect().top);
  }

  private _modalSetup(): void {
    if (this._modalScrollElement) {
      this._modalScrollElement.style.height = "auto";
      this.scrollable_modal = false;
      this.scroll_end = false;
      setTimeout(() => {
        if (this._modalScrollElement) {
          const { scrollHeight: modalScrollHeight } = this._modalScrollElement;
          const remaining_area = window.innerHeight - this._modalScrollElement.getBoundingClientRect().top;
          const is_modal_content_taller_than_window = modalScrollHeight > remaining_area - this._modalBottomOffset;

          if (is_modal_content_taller_than_window) this._modalScrollElement.style.height = `${remaining_area}px`;
          this._modalScrollElement.scrollTop = 0;

          // If the modal content is taller than the window, set it to scrollable
          this.scrollable_modal = is_modal_content_taller_than_window;

          this._setupScrollEl();
        }
      }, 0);
    }
  }

  public ngAfterViewInit() {
    if (this._modalScrollElement) {
      this._resizeTimer = null;
      window.onresize = () => {
        // Set the height back to auto so that it can be calulated accurately
        this._modalScrollElement.style.height = "auto";
        // Hide the modal whilst we resize so that the height doesnt jump around
        this.display = false;
        this.scrollable_modal = false;
        this.scroll_end = false;
        clearTimeout(this._resizeTimer);
        this._resizeTimer = setTimeout(() => {
          // Resizing has "stopped"
          this._modalSetup();
          this.display = true;
        }, 500);
      };
    }
    this._modalSetup();
    this._subs.sink = this._overlayService.onRecalculateOverlayScroll.subscribe(() => {
      this._modalSetup();
    });
  }

  private _setupClasses(): void {
    this._modalScrollElement.classList.add("relative", "z-40");
  }

  public close($event: Event): void {
    const element = this.modal.nativeElement;

    // Only close the modal if clicking outside the content
    if ($event.target === element && element.contains($event.target)) {
      this.display = false;
      setTimeout(() => {
        this._overlayService.close();
      }, FAST_ANIMATION_DURATION);
    }
  }

  private _setupScrollEl(): void {
    if (this.scrollable_modal) {
      this._modalScrollElement.classList.add("overflow-y-auto", "h-full");
      this._modalScrollElement.addEventListener("scroll", this._onScroll);
    } else {
      this._modalScrollElement.classList.remove("overflow-y-auto", "h-full");
      this._modalScrollElement.removeEventListener("scroll", this._onScroll);
    }
    this._setupClasses();
  }

  private _onScroll = ($event: Event) => {
    const currentTarget = <HTMLElement>$event.currentTarget;
    const target = <HTMLElement>$event.target;

    const currentScrollTop = currentTarget.scrollTop;
    // Scroll down
    if (currentScrollTop > this._lastScrollTop) {
      if (Math.round(target.offsetHeight + target.scrollTop) >= target.scrollHeight) {
        this.scroll_end = true;
      }

      // Scroll up
    } else if (this.scroll_end) {
      this.scroll_end = false;
    }
    this._lastScrollTop = currentScrollTop;
  };
}
