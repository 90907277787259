import { Subject, debounceTime } from "rxjs";
import { CacheService } from "../services/cache.service";

/**
 * Wraps a string so we can use the proxy object to persist the value
 */
export class StringWrapper<T extends string> {
  public value: T;
}

export class PersistentObjectFactory {
  private _onPropertyChange = new Subject<Record<string, any>>();
  private _ttlDuration: number | undefined = 1000 * 60 * 60;

  constructor(private _cacheService: CacheService, private _cacheKey: string) {
    this._onPropertyChange.pipe(debounceTime(10)).subscribe((object) => {
      this._cacheService.setJsonSession(this._cacheKey, object, this._ttl);
    });
  }

  public set ttlDuration(duration: number | undefined) {
    this._ttlDuration = duration;
  }

  private get _ttl(): number | undefined {
    if (this._ttlDuration === undefined) return undefined;
    return Date.now() + this._ttlDuration;
  }

  public create<T extends object>(object: T): T {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const cache = this._cacheService.getJsonSession(this._cacheKey);

    const proxy = new Proxy(cache || object, {
      set(target, prop, val) {
        self._onPropertyChange.next({ ...target, [prop]: val });

        return Reflect.set(target, prop, val);
      },
    }) as T;

    return proxy;
  }

  public clear(object: object): void {
    for (const key of Object.keys(object)) {
      delete object[key];
    }

    this._cacheService.deleteSession(this._cacheKey);
  }
}
