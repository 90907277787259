export abstract class AddressableItem {
  public address_line_1: string | null;
  public address_line_2: string | null;
  public address_line_3?: string | null;
  public town: string | null;
  public county: string | null;
  public postcode: string | null;

  public get address(): string {
    const parts = [this.address_line_1, this.address_line_2, this.address_line_3, this.town, this.county, this.postcode];

    return parts.filter((part) => part).join(", ");
  }
}
