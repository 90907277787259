export namespace BrowserCrypto {
  /**
   * Gets the SHA-256 hash of a text string to be used to cache the result of a GraphQL query using the hash of the query as the cache key
   *
   * @param text Text for which to generate a SHA-256 hash
   *
   * @returns Null, if the browser doesn't support SHA-256 hashing, or the SHA-256 hash string
   */
  export async function sha256(text: string): Promise<string | null> {
    if (!BrowserCrypto._isSupported()) {
      // Browser doesn't support SHA-256 hashing
      return null;
    }

    // Encode the text as a Uint8Array
    const encodedText = new TextEncoder().encode(text);
    // Generate the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest("SHA-256", encodedText);
    // Convert the hash to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((byte) => ("00" + byte.toString(16)).slice(-2)).join("");

    return hashHex;
  }

  export function _isSupported(): boolean {
    return !!crypto?.subtle?.digest;
  }
}
