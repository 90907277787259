import { E_ExamTypes } from "../../common/enums/exam-types";

export class AppointmentSettingsBase {
  public allow_appointment_cancellations = true;
  public practice_id?: string;
  public show_all_slots_new_patients = false;
  public required_to_select_site = true;
  public show_all_slots_existing_patients = false;
  public can_book_together = true;
  public min_wait_time = 0;
  public max_wait_time = 15;
  public max_days_ahead_bookings = 364;
  public lapsed_dental_duration = 24;
  public lapsed_hygiene_duration = 24;
  public hygiene_lapsing_exam_types: E_ExamTypes = E_ExamTypes.DENTAL;

  constructor(practice_id?: string) {
    if (practice_id) this.practice_id = practice_id;
  }
}
