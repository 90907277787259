import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { generateNanoId } from "@shared/utils";
import { TRACE_ID_HEADER } from "@shared/constants";

@Injectable({
  providedIn: "root",
})
export class SetHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const traceId = `patient-${generateNanoId()}`;
    const setHeaders = { "Content-Type": "application/json", [TRACE_ID_HEADER]: traceId };
    const authReq = req.clone({ setHeaders });
    return next.handle(authReq);
  }
}
