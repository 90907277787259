import { Component, OnInit, OnDestroy } from "@angular/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SubSink } from "subsink";
import { AppointmentsService } from "src/app/shared/services/appointments.service";
import { CommonService } from "src/app/shared/services/common.service";
import { MedicalHistoryService } from "src/app/shared/services/medical-history.service";
import { NhsPrService } from "src/app/shared/services/nhs-pr.service";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { AppointmentEntry, CancellationInfoEntry } from "src/app/data_model/appointment";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { PracticeCurrencyPipe } from "src/app/shared/pipe/practice-currency.pipe";
import { PracticeTimePipe } from "src/app/shared/pipe/practice-time.pipe";
import { LoadingComponent } from "../../loading/loading.component";
import { ModalComponent } from "../../modal/modal.component";
import { SHARED } from "src/app/shared/shared";
import { lastValueFrom } from "rxjs";
import { BookableApptsService } from "src/app/shared/services/bookable-appts.service";
import { E_Appointments_When } from "@backend/graph/appointments/appointment-base";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroExclamationCircleSolid } from "@ng-icons/heroicons/solid";

dayjs.extend(utc);

export interface CancelDialogData {
  appointment?: AppointmentEntry;
}

@Component({
  selector: "dentr-modal-cancel-appointment",
  templateUrl: "./modal-cancel-appointment.component.html",
  standalone: true,
  imports: [PracticeCurrencyPipe, PracticeTimePipe, LoadingComponent, ModalComponent, NgIconComponent, SHARED],
  providers: [provideIcons({ heroExclamationCircleSolid })],
})
export class ModalCancelAppointmentComponent implements OnInit, OnDestroy {
  public selectedAppointment: AppointmentEntry;
  public cancellation_info: CancellationInfoEntry | null = null;
  public showCard: "DEFAULT" | "LOADING" | "CANCELLING_DONE" | "ERROR" = "LOADING";
  public multiBookingOnSameDayWarning: any;
  public sitePhoneNumber: string;
  private _subs = new SubSink();
  constructor(
    private _commonService: CommonService,
    private _appointmentsService: AppointmentsService,
    private _navigationService: NavigationService,
    private _medicalHistoryService: MedicalHistoryService,
    private _nhsPrService: NhsPrService,
    private _overlayService: OverlayService,
    public bookableApptsService: BookableApptsService
  ) {
    const { data } = this._overlayService;
    if (!data || !data.appointment || !data.appointment.id || !data.appointment.start_time) {
      alert("Error loading appointment");
    } else {
      this.selectedAppointment = data.appointment;
      this.cancellation_info = this.selectedAppointment.cancellation_info;
      this.setup();
    }
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  setup(): void {
    this.sitePhoneNumber = this.selectedAppointment.practitioner.site_phone_number;
    this.showCard = "LOADING";

    // Fallback to practice phone number if the sites phone number is not available
    if (!this.sitePhoneNumber) this.sitePhoneNumber = this._commonService.practice.phone_number;

    this._subs.sink = this._appointmentsService.onAppointmentsChanged.subscribe((event) => {
      if (!event?.when || !event.appointments || event.when === E_Appointments_When.PAST) return;
      if (event.appointments.length) {
        if (event.appointments && this.selectedAppointment) {
          const cApt = dayjs(this.selectedAppointment.start_time);
          event.appointments.forEach((appointment) => {
            if (appointment.id?.toString() !== this.selectedAppointment.id?.toString()) {
              const isSame = cApt.isSame(dayjs(appointment.start_time), "day");
              if (isSame) {
                this.multiBookingOnSameDayWarning = appointment;
              }
            }
          });
        }

        this.cancellation_info = this.selectedAppointment.cancellation_info;
        if (this.cancellation_info && !this.cancellation_info.cancellation_screen) {
          this.cancellation_info.cancellation_screen = "CONTACT_TO_CANCEL";
        }
      }
    });
  }

  ngOnInit() {
    this.showCard = "DEFAULT";
  }

  public async cancelAppointment(): Promise<void> {
    this.showCard = "LOADING";

    const res = await lastValueFrom(this._appointmentsService.cancelAppointment(this.selectedAppointment));

    if (res) {
      this.showCard = "CANCELLING_DONE";

      await this._appointmentsService.getAppointments(E_Appointments_When.FUTURE, true);
      this._medicalHistoryService.getLatestMedicalHistory(true);
      this._nhsPrService.getPrData(true);
    } else {
      this.showCard = "ERROR";
    }
  }

  /**
   * Closes the modal and redirects the user to book appointment page
   */
  public rebookAppointment(): void {
    this._overlayService.close();

    this._navigationService.navigateToBooking();
  }

  public isAfter(ds: string) {
    return dayjs.utc(ds).isAfter(dayjs.utc());
  }

  public isBefore(ds: string) {
    return dayjs.utc(ds).isBefore(dayjs.utc());
  }

  close(): void {
    this._overlayService.close();
  }

  tryAgain(): void {
    this.setup();
  }
}
