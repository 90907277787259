import { ANY_PRACTITIONER_ID } from "@shared/constants";
import remove from "lodash/remove";

interface I_Practitioner {
  last_name?: string;
  first_name?: string;
  role?: string;
}

export function SortPractitioners(practitioners: Array<I_Practitioner>): void {
  // If we already have an entry for any practitioner, remove it as we want to make sure that this is always the first entry
  remove(practitioners, (practitioner) => practitioner.id === ANY_PRACTITIONER_ID);
  practitioners.sort((a, b) => {
    const criteriaA = `${a.last_name?.toUpperCase()} ${a.first_name?.toUpperCase()} (${a.role?.toUpperCase()})`;
    const criteriaB = `${b.last_name?.toUpperCase()} ${b.first_name?.toUpperCase()} (${b.role?.toUpperCase()})`;
    if (criteriaA > criteriaB) return 1;
    if (criteriaB > criteriaA) return -1;
    return 0;
  });
}
