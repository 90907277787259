export class PatientMedicalHistoryQuestionBase {
  details: string | null;
  follow_up_question: string | null;
  position: number;
  question_id: number;
  text: string | null;
  value: "True" | "False" | null;
  can_change?: boolean;
  tooltips?: Record<string, string>;
}

export class MedicalHistoryQuestionsBase {
  questions: Array<PatientMedicalHistoryQuestionBase>;
  patient_notes: string;
  id: number;

  constructor() {
    this.patient_notes = "";
    this.questions = [];
  }
}
