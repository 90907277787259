const colors = require("tailwindcss/colors");

// Palette must be kept in this specific order
module.exports = {
  "mustard-yellow": {
    50: "#FEFBF5",
    100: "#FDF2DE",
    200: "#FBDFAF",
    300: "#F9CC80",
    400: "#F7B952",
    500: "#F5A623",
    600: "#C3821A",
    700: "#905F12",
    800: "#5E3B09",
    900: "#2B1700",
  },
  pine: {
    50: "#F3F9F9",
    100: "#D8ECEC",
    200: "#A2D1D1",
    300: "#6CB7B7",
    400: "#369C9C",
    500: "#008282",
    600: "#006A6A",
    700: "#005252",
    800: "#003B39",
    900: "#002321",
  },
  "dentally-blue": {
    50: "#F3F8FF",
    100: "#D8E9FD",
    200: "#A2CBF8",
    300: "#6CADF4",
    400: "#368FEF",
    500: "#0071EB",
    600: "#005FC4",
    700: "#004D9E",
    800: "#003A77",
    900: "#00213D",
  },
  grape: {
    50: "#F8F2FF",
    100: "#EBD7FF",
    200: "#D0A1FF",
    300: "#B56CFF",
    400: "#9A36FF",
    500: "#7F00FF",
    600: "#6900D7",
    700: "#5200B0",
    800: "#3C0088",
    900: "#250060",
  },
  "deep-pink": {
    50: "#FFF2FF",
    100: "#FAD7FA",
    200: "#F0A1F0",
    300: "#E56CE5",
    400: "#DB36DB",
    500: "#D100D1",
    600: "#AB00AB",
    700: "#840086",
    800: "#5E0060",
    900: "#37003A",
  },
  ruby: {
    50: "#FFF1F8",
    100: "#FCD6E9",
    200: "#F7A1CC",
    300: "#F16BAE",
    400: "#EC3691",
    500: "#E60073",
    600: "#BB005F",
    700: "#90004B",
    800: "#650037",
    900: "#3A0023",
  },
  default: "var(--default)",
  "primary-contrast": "var(--primaryContrast)",
  "primary-shade": {
    50: "var(--primary-shade-50)",
    100: "var(--primary-shade-100)",
    150: "var(--primary-shade-150)",
    200: "var(--primary-shade-200)",
    250: "var(--primary-shade-250)",
    300: "var(--primary-shade-300)",
    350: "var(--primary-shade-350)",
    400: "var(--primary-shade-400)",
    450: "var(--primary-shade-450)",
    500: "var(--primary-shade-500)",
    600: "var(--primary-shade-600)",
    650: "var(--primary-shade-650)",
    700: "var(--primary-shade-700)",
    750: "var(--primary-shade-750)",
    800: "var(--primary-shade-800)",
    850: "var(--primary-shade-850)",
    900: "var(--primary-shade-900)",
  },
  primary: "var(--primary)",
  transparent: "transparent",
  current: "currentColor",
  gray: colors.gray,
  green: colors.green,
  black: colors.black,
  white: colors.white,
  gray: colors.gray,
  red: colors.rose,
  orange: colors.orange,
  sky: colors.sky,
};
