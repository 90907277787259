import { E_GlobalDomains } from "@shared/constants";

export function hideAppLoadingSpinner(): void {
  const appLoaderSpinner = document.getElementById("app_loader_spinner");
  if (appLoaderSpinner) appLoaderSpinner.outerHTML = "";
}

export function isRootDomain(stage?: string, hostname = window.location.hostname): boolean {
  const rootDomains: Array<string> = [E_GlobalDomains.SANDBOX, E_GlobalDomains.PRODUCTION];

  if (stage) {
    rootDomains.push(`${stage}.${E_GlobalDomains.SANDBOX}`);
  }

  return rootDomains.includes(hostname);
}
