import forOwn from "lodash/forOwn";
import { E_Patient_Actions_Type, PatientActionBase } from "../../../../../backend/src/graph/patient_actions/patient-action-base";

export class PatientActionsEntry extends PatientActionBase {
  // We use our own completed flag so we can control the view of actions via the FE and session storage
  public completed = false;
  public form_url: string | null = null;
  public skipped = false;
  constructor(baseItem: PatientActionBase, skipped = false) {
    const { practice_id, patient_id, appointment_id, type, text } = baseItem;
    super(practice_id, patient_id, appointment_id, type, text);

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });

    this.skipped = skipped;
  }

  public get callToAction(): string {
    switch (this.type) {
      case E_Patient_Actions_Type.ROUTINE_DENTAL_EXAM:
      case E_Patient_Actions_Type.ROUTINE_HYGIENE_APPT:
        return "Book now";
      case E_Patient_Actions_Type.PATIENT_DETAILS:
        return `${this.data.patient_details_completed_percentage}% complete`;
      default:
        return "Get started";
    }
  }
}
