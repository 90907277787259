<div
  #modal
  *ngIf="display"
  @scaleAnimation
  class="fixed inset-0 z-40"
  role="dialog"
  (click)="close($event)"
  aria-modal="true"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <div class="flex justify-center">
    <div
      class="fixed left-1/2 top-1/2 box-border inline-block max-h-[90vh] w-11/12 max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-2xl bg-white p-8 text-left align-bottom shadow-xl transition-all sm:w-3/4"
      [ngClass]="{
        'h-[80vh]': scrollable_modal
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
