export namespace Constants {
  export const BRAND_INFO_STORAGE_KEY = "brand-info";
  export const PUBLIC_JWT_STORAGE_KEY = "public-jwt";
  export const JWT_STORAGE_KEY = "jwt";
  export const PATIENT_ACTIONS_SESSION_STORAGE_KEY = "patient-actions";
  export const RESTRICTED_SITE_STORAGE_KEY = "restricted-site";
  export const LOGIN_REDIRECT_PATH_STORAGE_KEY = "redirectPath";
  export const MAX_PATIENT_AGE = 120;
  export const RECALL_APPT_LINK_DOB_STORAGE_KEY = "PATIENT_DOB";
  export const PATIENT_DETAILS_CONFETTI = "patient-details-confetti";
  export const BOOKING_SUCCESS_CONFETTI = "booking-success-confetti";
  export const PWA_SHORTCUT_STORAGE_KEY = "pwa-shortcut";
  export const SHORT_CODE_ID_STORAGE_KEY = "short-code-id";
  export const PIP_DATA_STORAGE_KEY = "pip-data";
  export const PIP_LOGIN_SHORTCUT_STORAGE_KEY = "pip-login-action";
  export const PIP_VISIT_ID_STORAGE_KEY = "pip-visit-id";

  class Route {
    private _segments: Array<string>;

    constructor(private _path: string) {
      this._segments = this._path.split("/");
    }

    public get last(): string {
      return this._segments[this._segments.length - 1];
    }

    public get full(): string {
      return this._path;
    }

    public get path(): string {
      return `/${this._path}`;
    }
  }

  export namespace ROUTES {
    export const UPDATE_MEDICAL_HISTORY = new Route("medical-history/update-medical-history");
    export const UPDATE_SMILE_SURVEY = new Route("smile-survey/update-smile-survey");
    export const ACCOUNT_BALANCE = new Route("account/balance");
    export const ACCOUNT_PAYMENT_SUCCESS = new Route("account/payment-success");
    export const ACCOUNT_INVOICES = new Route("account/invoices");
    export const LOGIN_RE_ENTER_PASSWORD = new Route("login/re-enter-password");
  }
}
