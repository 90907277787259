export enum E_MessageKind {
  None = "None",
  AcquisitionSource_Created = "AcquisitionSource_Created",
  AcquisitionSource_Deleted = "AcquisitionSource_Deleted",
  AcquisitionSource_Updated = "AcquisitionSource_Updated",
  AppointmentSettings_Updated = "AppointmentSettings_Updated",
  AppointmentType_Created = "AppointmentType_Created",
  AppointmentType_Deleted = "AppointmentType_Deleted",
  AppointmentType_Updated = "AppointmentType_Updated",
  Brand_Created = "Brand_Created",
  Brand_Deleted = "Brand_Deleted",
  Brand_Updated = "Brand_Updated",
  BrandCustomDomain_Created = "BrandCustomDomain_Created",
  BrandCustomDomain_Deleted = "BrandCustomDomain_Deleted",
  BrandDefaultDomain_Created = "BrandDefaultDomain_Created",
  BrandDefaultDomain_Deleted = "BrandDefaultDomain_Deleted",
  DownForMaintenance = "DownForMaintenance",
  Device_Created = "Device_Created",
  Device_Updated = "Device_Updated",
  Device_Deleted = "Device_Deleted",
  Device_Linked = "Device_Linked",
  Device_Unlinked = "Device_Unlinked",
  Device_Locked = "Device_Locked",
  Device_Unlocked = "Device_Unlocked",
  Device_Pinged = "Device_Pinged",
  Features_Updated = "Features_Updated",
  LoggedInUserSettings_Created = "LoggedInUserSettings_Created",
  LoggedInUserSettings_Updated = "LoggedInUserSettings_Updated",
  PracticeSettings_Updated = "PracticeSettings_Updated",
  Practitioner_Created = "Practitioner_Created",
  Practitioner_Deleted = "Practitioner_Deleted",
  Practitioner_Updated = "Practitioner_Updated",
  Refresh_Started = "Refresh_Started",
  Refresh_Progressed = "Refresh_Progressed",
  Refresh_Finished = "Refresh_Finished",
  SiteAllowedAppointmentType_Created = "SiteAllowedAppointmentType_Created",
  SiteAllowedAppointmentType_Deleted = "SiteAllowedAppointmentType_Deleted",
  SiteAppointmentType_Created = "SiteAppointmentType_Created",
  SiteAppointmentType_Deleted = "SiteAppointmentType_Deleted",
  SiteAppointmentType_Updated = "SiteAppointmentType_Updated",
  SiteAppointmentTypePractitioner_Created = "SiteAppointmentTypePractitioner_Created",
  SiteAppointmentTypePractitioner_Deleted = "SiteAppointmentTypePractitioner_Deleted",
  SiteAppointmentTypePractitioner_Updated = "SiteAppointmentTypePractitioner_Updated",
  SiteAppointmentTypeSession_Created = "SiteAppointmentTypeSession_Created",
  SiteAppointmentTypeSession_Deleted = "SiteAppointmentTypeSession_Deleted",
  SiteExternalPaymentProvider_Created = "SiteExternalPaymentProvider_Created",
  SiteExternalPaymentProvider_Updated = "SiteExternalPaymentProvider_Updated",
  SitePaymentPlanCategory_Updated = "SitePaymentPlanCategory_Updated",
  SitePaymentPlan_Created = "SitePaymentPlan_Created",
  SitePaymentPlan_Deleted = "SitePaymentPlan_Deleted",
  SitePaymentPlan_Updated = "SitePaymentPlan_Updated",
  SitePaymentPlanAllowance_Created = "SitePaymentPlanAllowance_Created",
  SitePaymentPlanAllowance_Deleted = "SitePaymentPlanAllowance_Deleted",
  SitePaymentPlanAllowance_Updated = "SitePaymentPlanAllowance_Updated",
  SitePaymentPlanDiscount_Created = "SitePaymentPlanDiscount_Created",
  SitePaymentPlanDiscount_Deleted = "SitePaymentPlanDiscount_Deleted",
  SitePaymentPlanDiscount_Updated = "SitePaymentPlanDiscount_Updated",
  SiteSettings_Updated = "SiteSettings_Updated",
  Site_Updated = "Site_Updated",
  UrlSiteAlias_Updated = "UrlSiteAlias_Updated",
  User_Updated = "User_Updated",
}
