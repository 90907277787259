/* eslint-disable @typescript-eslint/no-empty-interface */
import { StatusCodes as HttpStatusCodes } from "http-status-codes";
import { PatientBase } from "../../../graph/patients/patient-base";
import { LambdaRequest } from "./LambdaRequest";

export namespace LoginV4 {
  export interface code_sent_via {
    field?: "mobile_phone" | "email_address";
    value?: string;
  }
  export interface PatientAccount {
    id?: string;
    password?: string;
    attempts_left?: number;
    last_patient_login?: number;
    last_impersonator_login?: number;
    stage?: string;
  }

  export interface PatientSearchBase {
    mobile_phone?: string;
    email_address?: string;
    date_of_birth?: string;
    first_name?: string;
    last_name?: string;
    mobile_phone_country?: string;
    recaptcha_token?: string;
    postcode?: string;
  }

  export namespace Public {
    export namespace Search {
      export interface PostHTTPRequest extends PatientSearchBase {}

      export enum StatusCodes {
        INVALID_EMAIL_ADDRESS = "INVALID_EMAIL_ADDRESS",
        PATIENT_RECORD_NOT_FOUND = "PATIENT_RECORD_NOT_FOUND",
        PATIENT_PORTAL_ACCOUNT_FOUND = "PATIENT_PORTAL_ACCOUNT_FOUND",
        PATIENT_PORTAL_ACCOUNT_NOT_FOUND = "PATIENT_PORTAL_ACCOUNT_NOT_FOUND",
        MULTIPLE_PATIENT_RECORDS_FOUND = "MULTIPLE_PATIENT_RECORDS_FOUND",
        UNABLE_TO_VERIFY_IDENTITY = "UNABLE_TO_VERIFY_IDENTITY",
        ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
        ALREADY_LOGGED_IN = "ALREADY_LOGGED_IN",
      }

      export interface I_PatientData {
        first_name?: string;
        last_name?: string;
        date_of_birth?: string;
        email_address?: string;
        mobile_phone?: string;
      }

      export interface PostHTTPResponse {
        statusCode?: HttpStatusCodes;
        status_code?: StatusCodes;
        error?: any;
        request_body?: any;
        has_account?: boolean;
        practice_blocked?: boolean;
        account_locked?: boolean;
        locked_time_remaining?: number;
        patient_data?: I_PatientData;
        track?: boolean;
      }
      export interface PostHTTPFullResponse {
        data?: PostHTTPResponse;
      }
    }

    export namespace Authentication {
      export enum StatusCodes {
        SUCCESS = "SUCCESS",
        ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
        USER_NOT_VERIFIED = "USER_NOT_VERIFIED",
        FAILED_TO_CREATE_USER_ACCOUNT = "FAILED_TO_CREATE_USER_ACCOUNT",
        FAILED_TO_UPDATE_USER_ACCOUNT = "FAILED_TO_UPDATE_USER_ACCOUNT",
        INCORRECT_PASSWORD = "INCORRECT_PASSWORD",
        FAILED_TO_UPDATE_DENTALLY_PATIENT_RECORD = "FAILED_TO_UPDATE_DENTALLY_PATIENT_RECORD",
        ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
      }
      export interface PostHTTPResponse {
        statusCode?: HttpStatusCodes; // HTTP status code
        status_code?: StatusCodes; // Internal status code
        error?: any;
        redirect_domain?: string;
        redirect_is_old_world?: boolean;
        token?: string;
        // Used as a unique identifier for redirect JWT issuing
        uid?: string;
        attempts_left?: number;
      }
      export interface PostHTTPFullResponse {
        data?: PostHTTPResponse;
      }
      export interface PostHTTPRequest {
        password?: string | null;
        reset?: boolean;
        // Used as a unique identifier for redirect JWT issuing
        uid?: string;
      }
    }

    export namespace Verification {
      export namespace Check {
        export enum StatusCodes {
          LOADING = "LOADING",
          SUCCESS = "SUCCESS",
          ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
          ERROR_INVALID_VALIDAITON_CODE = "ERROR_INVALID_VALIDAITON_CODE",
          INVALID_TOKEN = "INVALID_TOKEN",
          MOBILE_ALREADY_VERIFIED = "MOBILE_ALREADY_VERIFIED",
        }
        export interface PostHTTPRequest {
          validation_code?: string;
        }
        export interface PostHTTPResponse {
          statusCode?: HttpStatusCodes;
          status_code?: StatusCodes;
          error?: any;
          email_verified?: boolean;
          mobile_verified?: boolean;
          account_locked?: boolean;
          jwt?: string;
        }
        export interface PostHTTPFullResponse {
          data?: PostHTTPResponse;
        }
      }
      export namespace Comms {
        export interface PostHTTPRequest {
          type?: code_sent_via["field"];
          resend?: boolean;
          reset?: boolean;
        }
        export enum StatusCodes {
          SUCCESS = "SUCCESS",
          ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
          ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
        }
        export interface PostHTTPResponse {
          statusCode?: HttpStatusCodes;
          status_code?: StatusCodes;
          error?: any;
          code_sent_via?: code_sent_via;
          locked_time_remaining?: number;
        }
        export interface PostHTTPFullResponse {
          data?: PostHTTPResponse;
        }
      }

      export namespace Details {
        export enum StatusCodes {
          SUCCESS = "SUCCESS",
          ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
          NOT_FOUND = "NOT_FOUND",
        }
        export interface GetHTTPResponse {
          statusCode?: HttpStatusCodes;
          status_code?: StatusCodes;
          error?: any;
          patient?: PatientSearchBase;
          token?: string;
          feature_flags?: { [key: string]: boolean | number | string };
        }
        export interface GetHTTPFullResponse {
          data?: GetHTTPResponse;
        }
      }

      export namespace MagicLinkRequest {
        export enum StatusCodes {
          SUCCESS = "SUCCESS",
          ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
          ERROR_SEND = "ERROR_SEND",
        }
        export interface PostHTTPRequest {
          resend?: boolean;
        }
        export interface PostHTTPResponse {
          statusCode?: HttpStatusCodes;
          status_code?: StatusCodes;
          error?: any;
        }
        export interface PostHTTPFullResponse {
          data?: PostHTTPResponse;
        }
      }

      export namespace PasswordStore {
        export enum StatusCodes {
          SUCCESS = "SUCCESS",
          ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
        }
        export interface PostHTTPRequest {}
        export interface PostHTTPResponse {
          statusCode?: HttpStatusCodes;
          status_code: StatusCodes;
          error?: any;
        }
        export interface PostHTTPFullResponse {
          data?: PostHTTPResponse;
        }
      }

      export namespace PasswordVerify {
        export enum StatusCodes {
          SUCCESS = "SUCCESS",
          ERROR_INVALID_REQUEST = "ERROR_INVALID_REQUEST",
          PASSWORD_MISMATCH = "PASSWORD_MISMATCH",
          PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS = "PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS",
        }
        export interface PostHTTPRequest {}
        export interface PostHTTPResponse {
          statusCode?: HttpStatusCodes;
          status_code: StatusCodes;
          error?: any;
        }
        export interface PostHTTPFullResponse {
          data?: PostHTTPResponse;
        }
      }

      export namespace EmailVerification {
        export enum Progress {
          SET_PASSWORD = "SET_PASSWORD",
          SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED",
          MAGIC_LINK_SENDING = "MAGIC_LINK_SENDING",
          MAGIC_LINK_SENT = "MAGIC_LINK_SENT",
          MAGIC_LINK_SEND_FAILED = "MAGIC_LINK_SEND_FAILED",
          VERIFY_PASSWORD = "VERIFY_PASSWORD",
          PASSWORD_VERIFIED = "PASSWORD_VERIFIED",
          PASSWORD_MISMATCH = "PASSWORD_MISMATCH",
          PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS = "PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS",
        }
      }
    }
  }

  export namespace Internal {
    export interface SmsLimit {
      id?: string;
      attempts_left?: number;
      ttl?: number;
    }
    export interface LoginFlow {
      id?: string;
      mobile_matches_patient_record?: boolean;
      verification?: {
        email_verified?: boolean;
        mobile_verified?: boolean;
        validation_code?: string;
        attempts_left?: number;
        code_sent_via?: "email_address" | "mobile_phone";
        expires?: string;
      };
      has_account?: boolean;
      form_data?: {
        first_name?: string;
        last_name?: string;
        mobile_phone?: string;
        email_address?: string;
        date_of_birth?: string;
        mobile_phone_country?: string;
      };
      patient?: PatientBase;
      practice_id?: string;
      ttl?: number;
      token?: string;
      password?: string;
      password_attempts?: number;
      magic_link_code?: string;
      user_impersonating_patient?: string;
      access_level?: LambdaRequest.enumAccessLevel;
    }
  }
}
