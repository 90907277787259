import { SHARED } from "src/app/shared/shared";
import { Component, Input } from "@angular/core";

type T_LoadingIconSize = "sm" | "md" | "lg";

@Component({
  selector: "dentr-loading",
  templateUrl: "./loading.component.html",
  standalone: true,
  imports: [SHARED],
})
export class LoadingComponent {
  @Input()
  public fill = "fill-primary";

  @Input()
  public set size(size: T_LoadingIconSize) {
    this._size = size;
  }

  public get sizeClass(): string {
    switch (this._size) {
      case "sm":
        return "w-8";
      case "md":
        return "w-12";
      case "lg":
        return "w-16";
    }
  }

  private _size: T_LoadingIconSize = "lg";
}
