import { Injectable } from "@angular/core";
import { AppointmentPractitionerEntry } from "src/app/data_model/appointment";
import { BookableAppointmentPractitionerEntry } from "src/app/data_model/bookable-appointment";
import * as palette from "../../../../palette";

@Injectable({
  providedIn: "root",
})
export class PractitionersService {
  private _palette = new Array<string>();
  private _practitionerColors: Record<string, string> = {};

  public addPractitionerColorClasses(practitioner: AppointmentPractitionerEntry | BookableAppointmentPractitionerEntry): void {
    if (!this._palette.length) this._palette = Object.keys(palette);

    const color = this._practitionerColors[practitioner.id] || this._palette.shift();

    practitioner.bg_class = `bg-${color}-200`;
    practitioner.text_class = `text-${color}-700`;

    if (color) this._practitionerColors[practitioner.id] = color;
  }
}
