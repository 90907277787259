import Bugsnag from "@bugsnag/js";

export function debugAndLeaveBreadcrumb(message: string, data: Record<string, any>): void {
  Bugsnag.leaveBreadcrumb(message, data);
  console.debug(message, data);
}

export function notifyAndLeaveBreadcrumb(message: string, data: Record<string, any>): void {
  Bugsnag.leaveBreadcrumb(message, data);
  Bugsnag.notify(message);
}
