import { UserBase } from "../users/user-base";

export class PractitionerBase {
  public id: string;
  public active: boolean;
  public colour: string;
  public site_id: string;
  public user_id: string;
  public job_title: string | null;
  public gdc_number: string | null;
  public qualifications: string | null;
  public biography: string | null;
  public image_url?: string | null;
  public user: UserBase;
  public full_name: string;
}
