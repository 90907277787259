import { Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { BehaviorSubject, Subject } from "rxjs";

export enum NotificationTypes {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
}

class NotificationConfig {
  title: string;
  body?: string;
  type?: NotificationTypes;
  timeout?: number;
  actionText?: string;
  actionFn?: () => any;
  onTextUpdate?: Subject<string>;
}

export class NotificationInstance extends NotificationConfig {
  constructor(notification: NotificationConfig) {
    super();
    this.title = notification.title;
    this.body = notification.body;
    this.type = notification.type || NotificationTypes.SUCCESS;
    this.timeout = notification.timeout ?? 5000;
    this.actionText = notification.actionText;
    this.actionFn = notification.actionFn;
    this.onTextUpdate = notification.onTextUpdate;

    this?.onTextUpdate?.subscribe((text) => {
      this.body = text;
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public onNotificationChanged = new BehaviorSubject<NotificationInstance | null>(null);

  public open(notification: NotificationInstance, err?: string): void {
    this.onNotificationChanged.next(new NotificationInstance(notification));
    if (err) Bugsnag.notify(new Error(err));
  }

  public close(): void {
    this.onNotificationChanged.next(null);
  }

  public reportError(title: string, err = ""): void {
    this.open(
      {
        title,
        body: err,
        type: NotificationTypes.ERROR,
      },
      err
    );
  }

  public reportWarning(title: string, err = ""): void {
    const body = `${err}<p class="mt-1">Please <span class="font-medium">reload</span> the page to fix the issue.</p>`;
    this.open(
      {
        title,
        body: body,
        type: NotificationTypes.WARNING,
        actionText: "Reload",
        actionFn: () => window.location.reload(),
      },
      err
    );
  }
}
