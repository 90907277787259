import { Injectable, OnDestroy } from "@angular/core";
import { HttpService } from "./http.service";
import { SubSink } from "subsink";
import { BehaviorSubject } from "rxjs";
import { Enums } from "@apis/_core/types/enums";
import { PatientAccountEntry } from "../../data_model/account";
import { CacheService } from "./cache.service";

export interface IProccessAccountConfig {
  charge_token: string;
  amount: number | string;
  action: string;
  appointment_id?: number | string;
}

export enum EAccountState {
  UPDATED = "UPDATED",
  LOADING = "LOADING",
}

type I_AccountsCache = {
  account?: PatientAccountEntry;
};

const FORCED_AMOUNT_TO_PAY_KEY = "forcedAmountToPay";

@Injectable({
  providedIn: "root",
})
export class AccountsService implements OnDestroy {
  public onAccountsChanged: BehaviorSubject<{
    status: EAccountState;
    account?: PatientAccountEntry | null;
  } | null> = new BehaviorSubject(null);
  private _cache: I_AccountsCache = {};
  private _subs = new SubSink();
  private _isFetching: any;
  constructor(private _httpService: HttpService, private _cacheService: CacheService) {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public get forcedAmountToPay(): number | null {
    const cacheString = this._cacheService.getSession(FORCED_AMOUNT_TO_PAY_KEY);

    if (!cacheString) return null;

    const cache = JSON.parse(cacheString);

    if (cache.ttl < Date.now()) {
      return null;
    }

    return Number(cache.amount);
  }

  public set forcedAmountToPay(amount: number | null) {
    if (amount === null) {
      this._cacheService.deleteSession(FORCED_AMOUNT_TO_PAY_KEY);
      return;
    }

    this._cacheService.setSession(
      FORCED_AMOUNT_TO_PAY_KEY,
      JSON.stringify({
        amount,
        ttl: Date.now() + 3600000,
      })
    );
  }

  public clearAccountCache(): void {
    this._cache = {};
  }

  getPatientsAccount(force?: boolean): void {
    if (this._isFetching) return;
    this.onAccountsChanged.next({
      status: EAccountState.LOADING,
      account: force ? null : this._cache.account,
    });
    if (this._cache.account && !force) {
      this.onAccountsChanged.next({
        status: EAccountState.UPDATED,
        account: this._cache.account,
      });
    } else {
      this._isFetching = true;
      this._subs.sink = this._httpService
        .get({
          url: `/payments/v1/patient/account`,
          params: { cache_bust: true },
        })
        .subscribe(
          (accountResponse) => {
            const entry = new PatientAccountEntry(accountResponse.data.account);
            this._cache.account = entry;
            const updateOb = {
              status: EAccountState.UPDATED,
              account: entry,
            };

            this.onAccountsChanged.next(updateOb);
            this._isFetching = false;
          },
          (err) => {
            this._isFetching = false;
            if (Enums.API_ERROR_TYPES.CUSTOM === err.type) {
              console.error(err);
              return;
            }
            throw err;
          }
        );
    }
  }
}
