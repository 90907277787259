<h2 class="heading-xxl mb-6">Patient portal Terms of Use</h2>
<p class="mb-3 font-normal">
  This online portal (the "Portal") provides a means of collecting important information requested by your dental care provider, which includes both dental
  practices and referral centres where applicable ("Dental Care Provider"), from you and passes that information to your Dental Care Provider. The Portal also
  interacts with the software used by your Dental Care Provider and accesses certain limited information from your dental records to assist you with the
  completion of subsequent forms on the Portal. The Portal is a platform supplied to your Dental Care Provider who has contracted with Software of Excellence UK
  Ltd ("Portal Provider") to provide the Portal as a processor on behalf of your Dental Care Provider. Your Dental Care Provider remains the data controller.
  These terms tell you the conditions for the use of the Portal.
</p>
<h3 class="heading-md mb-3 block">By using the Portal you accept these terms</h3>
<p class="mb-3 font-normal">
  By using the Portal, you confirm that you accept these terms of use. If you do not agree to these terms, you must not use the Portal.
</p>
<h3 class="heading-md mb-3 block">Dental Care Provider Privacy Policy.</h3>
<p class="mb-3 font-normal">
  In addition to these terms of use, your dental care provider's privacy policy also applies to your use of the Portal. Please refer to your Dental Care
  Provider's (Data Controller's) Privacy Policy for details as to what your personal information is used for, their legal reasons for doing so, how long they
  keep your personal information, your rights to request access to, rectification, erasure or restriction of processing and how to contact them regarding your
  legal rights. In addition to these terms of use, your Dental Care Provider's privacy policy also apply to your use of the Portal.  In all cases, your first
  point of contact is with your Dental Care Provider. Portal Provider's sole use and access to the data is for processing on behalf of your Dental Care Provider
  for the Dental Care Provider's legitimate interests in providing dental care to you in accordance with Portal Provider's contract with your Dental Care
  Provider.
</p>
<h3 class="heading-md mb-3 block">Adding Your Information to the Portal.</h3>
<p class="mb-3 font-normal">
  Whenever you make use of a feature that allows you to enter your information into the Portal, you must comply with the standards set out below. Any patient
  information provided must to your knowledge:
</p>
<ul>
  <li class="font-normal">
    <p class="mb-3 font-normal">Be current, true, accurate (where it states facts), supportable and complete</p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">Comply with the law applicable in England and Wales and in any country from which it is posted.</p>
  </li>
</ul>
<p class="mb-3 font-normal">
  You acknowledge that any information you supply does comply with the above, and that you will be responsible for any information supplied.
</p>
<h3 class="heading-md mb-3 block">You must keep your access credentials safe.</h3>
<p class="mb-3 font-normal">
  If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures (such as a
  link in an email), you must treat such information as confidential. If you know or suspect that anyone other than you, or a designated confidant, has accessed
  the portal you must promptly notify your Dental Care Provider immediately.
</p>
<h3 class="heading-md mb-3 block">Dental Care Provider websites.</h3>
<p class="mb-3 font-normal">
  Where the Portal contains links to other websites and resources provided by your Dental Care Provider, your Dental Care Provider is solely responsible for any
  websites associated with the Portal.
</p>
<h3 class="heading-md mb-3 block">Use of your personal information.</h3>
<p class="mb-3 font-normal">When you enter your information to the Portal, you acknowledge the following uses of your information</p>
<ul>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      To enable the transfer by Portal Provider of your information to your Dental Care Provider, in accordance with these terms of use and your Dental Care
      Provider's Privacy Policy.
    </p>
  </li>
  <li class="mb-3 font-normal"><span class="font-normal">To enable Portal Provider </span><strong>to only</strong><span class="font-normal">:</span></li>
  <ul>
    <li class="font-normal">
      <p class="mb-3 font-normal">
        Update your Dental Care Provider's dental records with information that you provide; and extract content from your dental record to assist you with the
        completion of subsequent forms on the Portal.
      </p>
    </li>
  </ul>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      The only actions undertaken by Portal Provider with your data is for the purpose of transferring information between you and your Dental Care Provider.
    </p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">Information is processed on servers based in the European Economic Area (EEA).</p>
  </li>
</ul>
<p class="mb-3 font-normal">Please contact your Dental Care Provider for details of how they handle and protect your data once transferred to them.</p>
<h3 class="heading-md mb-3 block">How long do we retain your information?</h3>
<p class="mb-3 font-normal">
  Information is retained on the Portal for no longer than necessary to allow you to complete your forms, complete patient check-in and review any
  post-appointment information. Completed forms are held by your Dental Care Provider with your dental records.There is no retention of information on this
  application once the processing activity is completed.
</p>
<h3 class="heading-md mb-3 block">Prohibited uses of the Portal</h3>
<p class="mb-3 font-normal">You may use the Portal only for lawful purposes. You may not use the Portal:</p>
<ul>
  <li class="font-normal">
    <p class="mb-3 font-normal">In any way that breaches any applicable local, national or international law or regulation.</p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">In any way that is fraudulent.</p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">To the harm or with the attempt to harm another person in any way.</p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      To knowingly transmit any data, send or upload any harmful programs or similar computer code designed to adversely affect the operation of any computer
      software or hardware.
    </p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">To infringe any other person's intellectual property rights.</p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">To breach any legal duty owed to a third-party, such as a contractual duty or a duty of confidence.</p>
  </li>
</ul>
<p class="mb-3 font-normal">You also agree:</p>
<ul>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      Not to access without authority, interfere with, damage or disrupt any part of the Portal or any hardware or software in relation to it.
    </p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      You must not misuse the Portal by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically
      harmful.
    </p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      You will not attempt to gain unauthorised access to the Portal, the server on which the Portal is stored, or any server, computer or database connected to
      the Portal.
    </p>
  </li>
  <li class="font-normal">
    <p class="mb-3 font-normal">
      You will not attack the Portal via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a
      criminal offence under the Computer Misuse Act 1990.
    </p>
  </li>
</ul>
<h3 class="heading-md mb-3 block">Other terms relating to the Portal.</h3>
<p class="mb-3 font-normal">
  Please note that these terms of use, their subject matter and their formation, are governed by English law. We may make changes to these terms and to the
  Portal from time to time. This platform has the capability to track anonymised usage details to assist with product improvements. Should you wish to disable
  this capability, please ensure you clear cookies from your browser and re-review the cookie consent.
</p>
<h3 class="heading-md mb-3 block">How to relay questions or comments regarding the Portal?</h3>
<p class="mb-3 font-normal">If you have any questions or comments regarding your use of the Portal, please contact your Dental Care Provider.</p>
