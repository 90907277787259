import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, timer } from "rxjs";
import { map, skip, switchMap, take } from "rxjs/operators";
import { HttpService } from "../shared/services/http.service";
import { ChatMessageBase, E_ChatMessageAction } from "@backend/graph/chat/chat-message-base";
import dayjs from "dayjs";
import { BookableApptsService } from "../shared/services/bookable-appts.service";
import { NavigationService } from "../shared/services/navigation.service";
import { LocationService } from "../shared/services/location.service";
import Bugsnag from "@bugsnag/js";

export interface I_ChatMessage {
  message: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
  from: string | null;
  timestamp: dayjs.Dayjs;
  delay?: number;
}

@Injectable({
  providedIn: "root",
})
export class ChatService {
  public onMessage = new Subject<I_ChatMessage>();
  public onTyping = new BehaviorSubject<boolean>(false);
  public onShowChatWindow = new BehaviorSubject<boolean>(false);
  private _messages = new Array<I_ChatMessage>();

  constructor(
    private _http: HttpService,
    private _bookableApptsService: BookableApptsService,
    private _navigationService: NavigationService,
    private _locationService: LocationService
  ) {}

  public toggleChatWindow(): void {
    const showChatWindow = !this.onShowChatWindow.value;

    this.onShowChatWindow.next(showChatWindow);

    if (showChatWindow) {
      if (!this._messages.length) {
        this.onTyping.next(true);

        this._addMessage({
          message: "Hello, how can I help you today?",
          from: "bot",
          timestamp: dayjs(),
          delay: 1000,
        });

        this._hideTyping(1000);
      }
    }
  }

  public sendMessage(message: string): void {
    this.onTyping.next(true);

    this._http
      .mutation(
        "sendChatMessage",
        `{
        sendChatMessage(message:{
          message:${JSON.stringify(message)}
          origin:"${this._locationService.hostname}"
        }) {
          message {
            text
            action
            data
          }
          options {
            label
            value
          }
          delay
        }
      }
    `
      )
      .pipe(
        map(
          (response: {
            data: {
              sendChatMessage: Array<ChatMessageBase>;
            };
          }) => response.data.sendChatMessage
        )
      )
      .subscribe((responses) => {
        let maxDelay = 0;

        if (responses?.length) {
          for (const response of responses) {
            if (response.message.text) {
              this._addMessage({
                delay: response.delay,
                message: response.message.text,
                from: "bot",
                timestamp: dayjs(),
                options: response.options,
              });
            } else {
              setTimeout(async () => {
                if (response.message.action === E_ChatMessageAction.BookAppointment) {
                  if (!response.message.data) {
                    Bugsnag.leaveBreadcrumb("ChatService: No data provided for booking appointment");
                    Bugsnag.notify(new Error("ChatService: No data provided for booking appointment"));
                    return;
                  }
                  const data = JSON.parse(response.message.data);
                  const queryParams = {
                    show_details: true,
                    ...data.queryParams,
                  };

                  this._bookableApptsService.selectedAppointment = null;
                  this._bookableApptsService.onBookableApptsChanged
                    .pipe(
                      skip(1), // ignore the current value
                      take(1), // only take the next value (remove the need to unsubscribe)
                      switchMap(() => timer(500)) // wait for other processing to complete
                    )
                    .subscribe(() => {
                      this._bookableApptsService.navigate(data.path, queryParams, false);
                      this._addMessage({
                        message: "Please select a time slot to book an appointment",
                        from: "bot",
                        timestamp: dayjs(),
                      });

                      setTimeout(() => {
                        this.onShowChatWindow.next(false);
                      }, 3000);
                    });

                  await this._bookableApptsService.createFromQueryString(queryParams);
                } else if (response.message.action === E_ChatMessageAction.Redirection) {
                  if (!response.message.data) {
                    Bugsnag.leaveBreadcrumb("ChatService: No data provided for redirection");
                    Bugsnag.notify(new Error("ChatService: No data provided for redirection"));
                    return;
                  }

                  const data = JSON.parse(response.message.data);

                  this._navigationService.navigate(data.path, data.queryParams);

                  setTimeout(() => {
                    this.onShowChatWindow.next(false);
                  }, 3000);
                }
              }, response.delay);
            }

            maxDelay = Math.max(maxDelay, response.delay ?? 0);
          }

          this._hideTyping(maxDelay);
        } else {
          this._addMessage({
            message: "Sorry, I don't understand",
            from: "bot",
            timestamp: dayjs(),
          });
        }
      });
  }

  private _addMessage(message: I_ChatMessage): void {
    this._messages.push(message);
    this.onMessage.next(message);
  }

  private _hideTyping(delay = 0): void {
    setTimeout(() => this.onTyping.next(false), delay);
  }
}
