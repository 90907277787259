<span *ngIf="is_modal; then thenBlock; else elseBlock"></span>
<ng-template #thenBlock>
  <dentr-modal>
    <div #modalScroll>
      <div class="pr-4">
        <ng-container *ngTemplateOutlet="legal"></ng-container>
        <button class="btn-secondary w-full sm:w-auto" (click)="closeModal()">Close</button>
      </div>
    </div>
  </dentr-modal>
</ng-template>
<ng-template #elseBlock>
  <ng-container *ngTemplateOutlet="legal"></ng-container>
</ng-template>

<ng-template #legal>
  <ng-container [ngSwitch]="iso_country_code">
    <p *ngSwitchCase="'GB'" class="mb-6">
      <strong>PLEASE NOTE THESE TERMS AND CONDITIONS APPLY TO YOUR USE OF THIS PORTAL</strong>
    </p>
    <p class="mb-6" *ngSwitchDefault><strong>PLEASE NOTE THESE TERMS AND CONDITIONS &amp; PRIVACY POLICY APPLY TO YOUR USE OF THIS PORTAL</strong></p>
  </ng-container>

  <dentr-login-terms-gb *ngIf="show_gb_terms"></dentr-login-terms-gb>
  <dentr-login-terms-default *ngIf="!show_gb_terms"></dentr-login-terms-default>

  <ng-container [ngSwitch]="iso_country_code">
    <dentr-privacy-policy-default [is_modal]="false" *ngSwitchDefault></dentr-privacy-policy-default>
  </ng-container>
</ng-template>
