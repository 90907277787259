import { SHARED } from "src/app/shared/shared";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { SubSink } from "subsink";
import { ModalComponent } from "../../modal/modal.component";

@Component({
  selector: "dentr-privacy-policy-default",
  templateUrl: "./privacy-policy-default.component.html",
  standalone: true,
  imports: [ModalComponent, SHARED],
})
export class PrivacyPolicyDefaultComponent implements OnInit, OnDestroy {
  @Input()
  public is_modal = true;
  private _subs = new SubSink();
  public iso_country_code = "GB";
  constructor(private _commonService: CommonService, private _overlayService: OverlayService) {
    this.iso_country_code = this._commonService.practice.iso_country_code;
  }

  ngOnInit() {
    this.iso_country_code = this._commonService.practice.iso_country_code;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public closeModal(): void {
    this._overlayService.close();
  }
}
