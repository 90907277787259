import { Injectable } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, Observable } from "rxjs";

interface I_Activity {
  timestamp: number;
}

@Injectable({
  providedIn: "root",
})
export class ActivityMonitorService {
  private _activity: BehaviorSubject<I_Activity> = new BehaviorSubject({ timestamp: Date.now() });
  private _focus: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get activity(): BehaviorSubject<I_Activity> {
    return this._activity;
  }

  public get lastActivity(): number {
    return this._activity.value.timestamp;
  }

  public get focus(): Observable<boolean> {
    return this._focus.pipe(distinctUntilChanged());
  }

  public track(): void {
    this._activity.next({
      timestamp: Date.now(),
    });
  }

  public onBlur(): void {
    this._focus.next(false);
  }

  public onFocus(): void {
    this._focus.next(true);
  }

  public clear(): void {
    const activity = {
      timestamp: Date.now(),
    };

    this._activity.next(activity);
  }
}
