import forOwn from "lodash/forOwn";
import { AppointmentSettingsBase } from "../../../../../backend/src/graph/appointment_settings/appointment-settings-base";

export class AppointmentSettingsEntry extends AppointmentSettingsBase {
  constructor(baseItem?: AppointmentSettingsBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
