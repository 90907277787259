import { SubSink } from "subsink";
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BrandInfoEntry } from "../data_model/brand-info";
import { CommonService } from "../shared/services/common.service";
import { JWTService } from "../shared/services/jwt.service";
import { NavigationService } from "../shared/services/navigation.service";
import { PatientsService } from "../shared/services/patients.service";
import { BrandService } from "../shared/services/brand.service";
import { BottomSheetService } from "../shared/services/bottom-sheet.service";
import { RouterModule, RouterOutlet } from "@angular/router";
import { FooterComponent } from "../shared/components/footer/footer.component";
import { UserMenuComponent } from "../shared/components/user-menu/user-menu.component";
import { SHARED } from "src/app/shared/shared";
import { FADE_IN_ANIMATION, ROUTE_ANIMATIONS } from "../shared/utils/animations";
import { LocationService } from "../shared/services/location.service";
import { PipLoginService } from "../shared/services/pip-login.service";
import Bugsnag from "@bugsnag/js";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroArrowLeftOnRectangle } from "@ng-icons/heroicons/outline";
import { SessionService } from "../shared/services/session.service";
import { ActivityMonitorService } from "../shared/services/activity-monitor.service";
import { Constants } from "src/constants";
import { environment } from "src/environments/environment";
import { hideAppLoadingSpinner } from "../utils";

@Component({
  selector: "dentr-main",
  templateUrl: "./main.component.html",
  standalone: true,
  imports: [RouterModule, FooterComponent, UserMenuComponent, SHARED, FooterComponent, NgIconComponent],
  animations: [ROUTE_ANIMATIONS, FADE_IN_ANIMATION],
  host: { class: "block h-full" },
  providers: [provideIcons({ heroArrowLeftOnRectangle })],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewChecked {
  private _subs = new SubSink();
  private _appView: boolean;
  private _loading = true;
  public brand: BrandInfoEntry;
  public cachedBrand: BrandInfoEntry;
  public showMenu = false;
  public appVersion = environment.VERSION;
  public loggingOut = false;
  get appView(): boolean {
    return this._appView;
  }
  @Input() set appView(value: boolean) {
    this._appView = value;
    this.hideUserMenu = this._jwtService.isPatientUnauthenticated() || !this._jwtService.isLoggedIn() || this._appView;
  }
  @Input()
  public hideTopNav = false;

  /**
   * ** IMPORTANT **
   *
   * Do not remove this function because it prevents PI from being exposed in Safari after the patient has logged out by clicking the back button
   *
   * @param event
   */
  @HostListener("window:pageshow", ["$event"])
  public onWindowShow(event) {
    if (event.persisted && !this._jwtService.isLoggedIn()) {
      // Clear the body to ensure that the page does not expose any PI
      const body = document.querySelector("body");
      if (body) body.innerHTML = "";

      // Reload the page to ensure that we get go back to the login screen
      window.location.reload();
    }
  }

  @HostListener("document:click")
  @HostListener("document:keypress")
  @HostListener("document:touchstart")
  @HostListener("document:touchend")
  @HostListener("document:touchcancel")
  @HostListener("document:touchmove")
  public onPatientActivity() {
    this._activityMonitorService.track();
  }

  @HostListener("window:blur")
  public onBlur() {
    this._activityMonitorService.onBlur();
  }

  @HostListener("window:focus")
  public onFocus() {
    this._activityMonitorService.onFocus();
  }

  @ViewChild("mainContentWrapper") mainContentWrapper: ElementRef;
  public hideUserMenu = true;

  constructor(
    private _commonService: CommonService,
    private _navigationService: NavigationService,
    private _jwtService: JWTService,
    private _brandService: BrandService,
    private _bottomSheetService: BottomSheetService,
    private _cdr: ChangeDetectorRef,
    private _locationService: LocationService,
    private _pipLoginService: PipLoginService,
    private _sessionService: SessionService,
    private _activityMonitorService: ActivityMonitorService,
    public patientsService: PatientsService
  ) {}

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(value: boolean) {
    const content_wrapper = document.getElementById("content-wrapper");
    if (content_wrapper) content_wrapper.remove();
    hideAppLoadingSpinner();

    this._loading = value;
  }

  public get showAppVersion(): boolean {
    return this._locationService.isPairDomain;
  }

  // #region Angular lifecycle hooks
  public prepareRoute(outlet: RouterOutlet) {
    // Disable animations for e2e tests
    const isE2E = window.localStorage.getItem("e2e");
    if (isE2E) return;
    return outlet?.isActivated && (outlet.activatedRouteData?.animation ? outlet.activatedRouteData.animation : outlet.activatedRoute);
  }

  // Needed to ensure the animation change detection is triggered correctly
  public ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  public async ngOnInit() {
    Bugsnag.leaveBreadcrumb("Page loaded");

    this._bindEvents();

    if (this._jwtService.isPatientInPractice()) {
      this._pipLoginService.startInactivityMonitor();
    }

    if (this._shouldBypassGetCommonData) {
      this.loading = false;
      return;
    }

    this._commonService.getCommonData(true);

    // Subset of the brand info that is cached in the browser from the app initialization. Keeping this separate from the brand property to ensure that we do not get change detection issues with brand data
    this.cachedBrand = this._brandService.brand;

    if (this._locationService.pathname !== "/login/redirect") this._jwtService.getJWT();

    if (!this._inIframe() && this._jwtService.isImpersonating()) {
      console.log("Logging out because we are impersonating but not in an iframe");
      await this._sessionService.clear();
    }

    this._sessionService.startKeepAlive();

    this._subs.sink = this._pipLoginService.onLogout.subscribe(() => {
      this.loggingOut = true;
    });
  }

  private get _shouldBypassGetCommonData(): boolean {
    if (this._locationService.isPairDomain || this._locationService.pathname.endsWith("/pair") || this._locationService.pathname.endsWith("/error-404")) {
      return true;
    }

    if (this._locationService.pathname === Constants.ROUTES.LOGIN_RE_ENTER_PASSWORD.path || this._sessionService.isPasswordRequired) {
      return true;
    }

    if (this._locationService.isRootDomain && environment.IS_STAGE_UNKNOWN) {
      // We're not going to get a response from the API because the stage is unknown (i.e. there is no short code or cache entry) so we can bypass the loading screen
      return true;
    }

    return false;
  }

  public async signout(): Promise<void> {
    if (this.loggingOut) return;

    this.loggingOut = true;
    const isPip = this._jwtService.isPip();
    if (isPip) await this._pipLoginService.logoutPatientInPractice();
    else {
      await this._sessionService.clear();
      this.navigateToHome();
    }
  }

  // Method to determine if unauthenticated users should be able to logout. Logout for authenticated users is handled by the header component
  public get canUnauthenticatedLogout(): boolean {
    if (!this.isPatientUnauthenticated) return false;

    const route = this._locationService.pathname;
    const is_login = route.includes("/login");

    if (is_login) return false;

    return !this.appView;
  }
  public get showFooter(): boolean {
    if (this.isPip) return false;
    if (this.appView) return false;
    /*
      Hide the footer when we dont have a JWT or the JWT is public on the root domain.
      The footer has links that are practice specific and we wont know the practice at this point
    */
    if (this._locationService.isRootDomain && (!this._jwtService.getJWTString() || this._jwtService.isPUBLIC())) return false;

    return true;
  }

  public get isPip(): boolean {
    return this._jwtService.isPip();
  }

  public get isPatientUnauthenticated() {
    return this._jwtService.isPatientUnauthenticated();
  }

  private _inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
  // #endregion

  // #region event subscriptions
  private _bindEvents() {
    this._subs.sink = this._commonService.onInitData.subscribe(
      () => {
        this.loading = false;
      },
      () => {
        // Bypass the loading setter so that the content wrapper is not removed
        this._loading = false;
      }
    );

    this._subs.sink = this._bottomSheetService.onBottomSheetOffset.subscribe((height) => {
      this.mainContentWrapper.nativeElement.style["padding-bottom"] = `${height}px`;
    });
  }
  // #endregion

  // #region Misc methods
  public navigateToHome() {
    this._navigationService.navigate("/");
  }

  public get isLoggedIn() {
    return this._jwtService.isLoggedIn();
  }
  // #endregion
}
