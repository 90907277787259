import Bugsnag from "@bugsnag/js";

export const handleLazyLoadError = (error: any) => {
  const regex = /Loading chunk (.+?) failed/;

  const chunkError = error?.message?.match(regex);
  if (chunkError) {
    Bugsnag.notify(new Error(`Chunk loading error: ${chunkError[1]}`));
  }
};

export const loadComponentWithErrorHandling = (importFunc: () => Promise<any>) => {
  return () => {
    return importFunc().catch(handleLazyLoadError);
  };
};
