<div class="my-6 flex items-center justify-end">
  <div class="relative inline-block text-left">
    <div
      role="button"
      class="inline-flex w-full cursor-pointer items-center justify-center"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
      tabindex="0"
      (keydown.enter)="onClick($event)"
      data-e2e-menu-button
    >
      <div class="flex items-center" (keydown.enter)="navigateToLandingPage()" (click)="navigateToLandingPage()">
        <h3 class="text-sm font-medium text-gray-700">
          <span class="data-hj-suppress block max-w-[12rem] truncate capitalize text-gray-700" *ngIf="isLoggedIn && patientName">{{ patientName }}</span>
          <span *ngIf="!isLoggedIn && !isPatient">Log in / register</span>
        </h3>
        <div class="ml-3 flex items-center" *ngIf="(isLoggedIn && patientName) || !isLoggedIn">
          <span
            class="relative h-10 w-10 overflow-hidden rounded-full shadow-sm"
            [ngClass]="[isLoggedIn ? patientsService.patientInfo?.bg_class + ' flex items-center justify-center' : 'bg-gray-100']"
          >
            <div *ngIf="!isLoggedIn" class="flex justify-center">
              <span class="absolute top-[20%] h-3.5 w-3.5 rounded-full bg-gray-300"></span>
              <span class="absolute top-[63%] h-10 w-10 rounded-full bg-gray-300"></span>
            </div>
            <div *ngIf="isLoggedIn">
              <span class="font-semibold uppercase" [ngClass]="patientsService.patientInfo?.text_class">
                {{ patientsService.patientInfo?.first_name.charAt(0) }}{{ patientsService.patientInfo?.last_name.charAt(0) }}
              </span>
            </div>
          </span>
          <ng-icon name="heroChevronDown" class="ml-2 text-gray-900" strokeWidth="3" *ngIf="isLoggedIn && patientName"></ng-icon>
        </div>
      </div>
    </div>

    <div
      *ngIf="showMenu"
      class="absolute top-[100%] right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-200 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
    >
      <ul *ngIf="isLoggedIn" role="none">
        <li
          tabindex="0"
          (keydown.enter)="navigateTo('/my-dental/contact-details')"
          (click)="navigateTo('/my-dental/contact-details')"
          class="flex cursor-pointer items-center px-4 py-3 text-base hover:bg-gray-50"
        >
          <span class="flex items-center text-[1.25rem]">
            <ng-icon name="heroIdentification" class="text-gray-400"></ng-icon>
          </span>

          <a class="ml-2 text-sm font-normal text-gray-700">Your details</a>
        </li>

        <li
          tabindex="0"
          (click)="signOut()"
          (keydown.enter)="(signOut)"
          class="flex cursor-pointer items-center border-0 border-t border-solid border-gray-100 px-4 py-3 text-base hover:bg-gray-50"
          data-e2e-logout
        >
          <span class="flex items-center text-[1.25rem]">
            <ng-icon name="heroArrowLeftOnRectangle" class="text-gray-400"></ng-icon>
          </span>
          <a class="ml-2 text-sm font-normal text-gray-700">Log out</a>
        </li>
      </ul>
    </div>
  </div>
</div>
