import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CacheService } from "./cache.service";
import { BrandInfoBase } from "@backend/graph/brand_info/brand-info-base";

export interface Analytics_Google {
  tracking_id?: string;
  tracking_link_domain?: string;
  tracking_cookieDomain?: string;
}
@Injectable({
  providedIn: "root",
})
export class GAService {
  private _hasBeenSetup = false;
  private _clientTrackingId: any = null;
  private _initRetryCount = 0;
  private _initMaxRetryCount = 5;

  constructor(private _cacheService: CacheService) {}

  private _parseJwt(token): any {
    if (!token) {
      return false;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
  public setup(brandInfo: BrandInfoBase) {
    const raw_jwt = this._cacheService.get("jwt");
    let jwt: any = {};
    try {
      jwt = this._parseJwt(raw_jwt);
    } catch (err) {}

    if (jwt.practice_id && typeof window.ga !== "undefined") {
      if (!this._hasBeenSetup) {
        window.ga("create", environment.GA_TRACKING_ID_PATIENT_APP, "auto");
        window.ga("send", "pageview");
        this._hasBeenSetup = true;
        if (brandInfo && brandInfo.ga_tracking_id) {
          this._setupClientTracker(jwt, brandInfo);
        }
      }
    } else {
      this._initRetryCount += 1;
      if (this._initRetryCount === this._initMaxRetryCount) {
        return;
      }

      if (!!window.gtag) {
        window.gtag("js", new Date());
        window.gtag("config", environment.GA_TRACKING_ID_PATIENT_APP);
      }

      setTimeout(() => {
        this.setup(brandInfo);
      }, 1000);
    }
  }

  // http://pollen.io/google-analytics-applications/
  /**
   * @deprecated
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   */
  public emitEvent(eventCategory: string, eventAction: string, eventLabel: string | null = null) {
    window.ga("send", {
      hitType: "event",
      eventCategory,
      eventLabel,
      eventAction,
    });
    if (this._clientTrackingId) {
      window.ga("clientTracker.send", {
        hitType: "event",
        eventCategory,
        eventLabel,
        eventAction,
      });
    }
  }
  /**
   *
   * @param action send event to GA
   */
  public action(action: string) {
    try {
      window.ga("send", "event", "action", action, null);
      if (this._clientTrackingId) {
        window.ga("clientTracker.send", "event", "action", action, null);
      }
    } catch (e) {}
  }

  public error(errorObject) {
    try {
      window.ga("send", "exception", errorObject);
    } catch (e) {}
  }

  public goal(goalReached) {
    try {
      window.ga("send", "event", "goal", "success", goalReached, 1);
      if (this._clientTrackingId) {
        window.ga("clientTracker.send", "event", "goal", "success", goalReached, 1);
      }
    } catch (e) {}
  }
  public setPage(pageName: string) {
    try {
      window.ga("send", "page", pageName);

      if (this._clientTrackingId) {
        window.ga("clientTracker.send", "page", pageName);
      }
    } catch (e) {}
  }

  public pageview(page: string) {
    try {
      window.ga("send", "pageview", page);
      if (this._clientTrackingId) {
        window.ga("clientTracker.send", "pageview", page);
      }
    } catch (e) {}
  }

  public _setupClientTracker(jwt: any, brandInfo: BrandInfoBase) {
    const { ga_tracking_id, ga_tracking_link_domain, ga_tracking_cookie_domain } = brandInfo;

    if (this._hasBeenSetup && ga_tracking_id) {
      const create = {
        trackingId: ga_tracking_id,
        cookieDomain: ga_tracking_cookie_domain || "auto",
        name: "clientTracker",
        allowLinker: !!ga_tracking_link_domain,
      };
      window.ga("create", create);

      this._clientTrackingId = ga_tracking_id;

      if (ga_tracking_link_domain) {
        let tld: any = [ga_tracking_link_domain];
        if (ga_tracking_link_domain.indexOf(",") > -1) {
          tld = ga_tracking_link_domain.split(",").map((item) => {
            if (item) {
              item = item.trim();
            }
            return item;
          });
        }
        window.ga("clientTracker.require", "linker");
        window.ga("clientTracker.linker:autoLink", tld);
      }

      if (jwt.site_id) {
        window.ga("clientTracker.set", "dimension1", jwt.site_id);
      }
      if (jwt.practice_id) {
        window.ga("clientTracker.set", "dimension2", jwt.practice_id);
      }
    }
  }
}
