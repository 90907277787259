import { Renderer2 } from "@angular/core";
import { Observable, filter, fromEventPattern, lastValueFrom, map, take, timeout } from "rxjs";
import { debugAndLeaveBreadcrumb } from "./logging";

export enum E_SecureCrossDomainDataSharingType {
  PIP_PAIRING = "pip-pairing",
}

interface I_SecureCrossDomainDataSharingConfig {
  targetUrl: string;
  type: E_SecureCrossDomainDataSharingType;
  messageId: string;
}

function startPostMessageListener(renderer: Renderer2, id: string): Observable<MessageEvent> {
  // Create an observable to listen for messages from the iframe
  let removeListener: () => void;
  const message = fromEventPattern<MessageEvent>(
    (handler) => {
      removeListener = renderer.listen("window", "message", handler);
    },
    () => removeListener()
  );

  // Setup an observer to listen for domain redirection message from the iframe which will be added to the page below
  return message.pipe(
    // Wait for 30 seconds for the message to come through, if it doesn't an error will be thrown
    timeout(30000),
    // Filter out any messages that aren't the domain redirection message
    filter((event) => event?.data?.id === id),
    // Take the first message that comes through. This will complete the subscription and stop listening for messages
    take(1)
  );
}

/**
 * 1. startSecureCrossDomainDataSharing is called on the original tab to start a listener and open a new tab
 * 2. new tab loads and calls finaliseSecureCrossDomainDataSharing which sends a message to the original tab to say its loaded
 * 3. original tab receives message and sends the data to the new tab
 */
export function startSecureCrossDomainDataSharing(renderer: Renderer2, config: I_SecureCrossDomainDataSharingConfig, data: Record<string, any>): void {
  debugAndLeaveBreadcrumb("startSecureCrossDomainDataSharing", { config, data });
  const { messageId, targetUrl } = config;
  const listener = startPostMessageListener(renderer, messageId);
  const { hostname } = new URL(targetUrl);

  listener.subscribe((event) => {
    const message = {
      id: messageId,
      ...data,
    };
    const options = {
      targetOrigin: `https://${hostname}`,
    };

    debugAndLeaveBreadcrumb("event.source.postMessage", { message, options });
    event.source?.postMessage(message, options);
  });

  debugAndLeaveBreadcrumb("opening window", { targetUrl });
  window.open(targetUrl, "_blank");
}

export async function finaliseSecureCrossDomainDataSharing<T>(renderer: Renderer2, messageId: string, url: string): Promise<T | null> {
  try {
    debugAndLeaveBreadcrumb("finaliseSecureCrossDomainDataSharing", { messageId, url });
    const listener = startPostMessageListener(renderer, messageId);

    window.opener.postMessage(
      {
        id: messageId,
      },
      url
    );

    return await lastValueFrom<T>(listener.pipe(map((event) => event.data)));
  } catch (e) {
    console.error("Error finalising secure cross domain data sharing", messageId, url, e);
    return null;
  }
}
