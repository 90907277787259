import { Injectable, isDevMode } from "@angular/core";
import { Observable } from "rxjs";
import { HttpServiceAbstract } from "./http.abstract.service";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "./logger.service";
import { environment } from "../../../environments/environment";
import { JWTService } from "./jwt.service";

export enum GRAPHQL_OPERATION_NAMES {
  ADDRESS_AUTOCOMPLETE = "addressAutocomplete",
  ADDRESS_SUGGESTIONS = "addressSuggestions",
  APPOINTMENT = "appointment",
  APPOINTMENTS = "appointments",
  AVAILABILITY = "availability",
  COMMON = "common",
  ESTIMATE = "estimate",
  FIND_GP_PRACTICES = "findGpPractices",
  GET_APPOINTMENT_PRICES = "getAppointmentPrices",
  GET_BOOKABLE_APPOINTMENTS = "bookableAppointments",
  MEDICAL_HISTORY = "medicalHistory",
  MEDICAL_HISTORY_HAS_YES_ANSWERS = "medicalHistoryHasYesAnswers",
  MEDICAL_HISTORY_QUESTIONS = "medicalHistoryQuestions",
  MEDICAL_HISTORY_UPDATED_AT = "medicalHistoryUpdatedAt",
  NHS_PR = "nhsPr",
  PATIENT = "patient",
  PATIENT_ACTIONS = "patientActions",
  PIP_AUTHORIZE = "pipAuthorize",
  RECALL_APPOINTMENT_LINK = "recallAppointmentLink",
  SHORT_CODE = "shortCode",
  SMILE_SURVEY = "smileSurvey",
  SMILE_SURVEY_HAS_YES_ANSWERS = "smileSurveyHasYesAnswers",
  SMILE_SURVEY_QUESTIONS = "smileSurveyQuestions",
  SMILE_SURVEY_UPDATED_AT = "smileSurveyUpdatedAt",
  URL_SITE_ALIAS = "urlSiteAlias",
  VERIFY_PATIENT_DETAILS = "verifyPatientDetails",
}

@Injectable({
  providedIn: "root",
})
export class HttpService extends HttpServiceAbstract {
  public constructor(http: HttpClient, loggerService: LoggerService, jwtService: JWTService) {
    super(http, loggerService, jwtService);
  }

  public get(req: { url: string; params?: Params; expires?: number; force?: boolean; uri?: string }): Observable<any> {
    if (req.params && req.params.cache_bust) {
      req.params.cache_bust = this._cacheBustId();
    }

    if (!isDevMode()) {
      if (!req.params) {
        req.params = {};
      }
      req.params.cache_bust = this._cacheBustId();
    }

    if (req.force) {
      if (!req.params) {
        req.params = {};
      }
      req.params.cache_bust = this._cacheBustId();
    }
    req.url = environment.REST_LEGACY_URL + req.url;
    return this.send(req.url, { method: "GET", params: req.params });
  }

  public head(url: string): Observable<any> {
    return this.send(url, { method: "HEAD" });
  }

  public options(url: string): Observable<any> {
    url = environment.REST_LEGACY_URL + url;
    return this.send(url, { method: "OPTIONS" });
  }
  public optionsLegacy(url: string): Observable<any> {
    url = `${environment.REST_LEGACY_URL}/v1/${url}`;
    return this.send(url, { method: "OPTIONS" });
  }

  public delete(url: string, body: any): Observable<any> {
    url = environment.REST_LEGACY_URL + url;
    return this.send(url, { params: body, method: "DELETE" });
  }
  public deleteLegacy(url: string, body: any): Observable<any> {
    url = `${environment.REST_LEGACY_URL}/v1/${url}`;
    return this.send(url, { body, method: "DELETE" });
  }

  public post(url: string, body: any): Observable<any> {
    url = environment.REST_LEGACY_URL + url;
    return this.send(url, { body, method: "POST" });
  }

  // Used for GraphQL calls
  public query<T>(operationName: GRAPHQL_OPERATION_NAMES, query: string, options: any = {}): Observable<T | any> {
    const namedQuery = `query ${operationName} ${query}`;
    return this.send<T>(this._graphUrl, {
      body: {
        query: namedQuery,
        operationName,
      },
      method: "POST",
      ...options,
    });
  }

  public mutation<T>(operationName: string, query: string, options: any = {}): Observable<T | any> {
    const namedMutation = `mutation ${operationName} ${query}`;
    return this.send<T>(this._graphUrl, {
      body: {
        query: namedMutation,
        operationName,
      },
      method: "POST",
      ...options,
    });
  }

  public postLegacy(url: string, body: any): Observable<any> {
    url = `${environment.REST_LEGACY_URL}/v1/${url}`;
    return this.send(url, { body, method: "POST" });
  }

  public put(url: string, body: any): Observable<any> {
    return this.send(url, { body, method: "PUT" });
  }

  public patch(url: string, body: any): Observable<any> {
    return this.send(url, { body, method: "PATCH" });
  }

  private _cacheBustId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private get _graphUrl() {
    return environment.GRAPH_URL;
  }
}

/**
 * Define type for valid parameters
 */
export type Params = string | URLSearchParams | any | { [key: string]: any | any[] };
