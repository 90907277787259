import { T_CommsMethod } from "../../../graph/patient-short-codes/patient-short-code-base";

export namespace LambdaRequest {
  export interface requestContext {
    accountId?: string;
    apiId?: string;
    authorizer?: {
      lambda: authorizer;
    };
    domainName?: string;
    domainPrefix?: string;
    http?: {
      method: string;
      path: string;
      protocol: string;
      sourceIp: string;
      userAgent: string;
    };
    identity?: {
      sourceIp?: string;
    };
    requestId?: string;
    routeKey?: string;
    stage?: string;
    time?: string;
    timeEpoch?: number;
  }
  export interface event {
    path?: string;
    httpMethod?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD" | "OPTIONS";
    version?: string;
    routeKey?: string;
    rawPath?: string;
    rawQueryString?: string;
    cookies?: string[];
    headers?: { [name: string]: string };
    queryStringParameters?: queryStringParameters;
    Records?: any;
    requestContext?: requestContext;
    body?: string;
    pathParameters?: { [name: string]: string };
    isBase64Encoded?: boolean;
    stageVariables?: { [name: string]: string };
  }
  export interface queryStringParameters {
    [name: string]: string;
  }
  export interface authorizer {
    practice_id?: string;
    patient_id?: string | number;
    user_impersonating_patient?: string;
    uid?: string;
    site_id?: string;
    access_level?: enumAccessLevel;
    scopes?: string[];
    jwt?: string;
    comms_method?: T_CommsMethod;
    is_verified?: boolean | string;
    referrer?: string;
    /**
     * has domain level settings
     * */
    domain_settings?: boolean;
    /* limit to sites */
    limit_sites?: boolean;

    domain?: string;
    practice_name?: string;
    payment_plan_id?: number;
    /**
     * Version of JWT token issued
     */
    version?: string;

    /**
     * sid is a unique id for users session (regardless of login or not it stays with them)
     */
    sid?: string;

    /**
     * Set but authorizer function - Dentally access token
     */
    access_token?: string;
    exp?: number;
    claims?: string[];
    iat?: number;
    restricted_mode?: boolean;
  }

  export enum enumAccessLevel {
    INTERNAL = "INTERNAL",
    MANAGE_UI = "MANAGE_UI",
    PUBLIC = "PUBLIC",
    PUBLIC_IN_PRACTICE = "PUBLIC_IN_PRACTICE", // Used when search for a patient in practice (similar to PUBLIC)
    PATIENT = "PATIENT",
    PATIENT_UNAUTHENTICATED = "PATIENT_UNAUTHENTICATED",
    PATIENT_IN_PRACTICE = "PATIENT_IN_PRACTICE", // Used when a patient in practice has verified their details (similar to PATIENT_UNAUTHENTICATED)
    PATIENT_EXPIRED_SESSION = "PATIENT_EXPIRED_SESSION", // Used when a patient has an expired session (JWT is still valid but the session has expired in global-user-sessions table)
    PRACTICE_ADMIN = "PRACTICE_ADMIN",
  }
  export const publicAccessLevels = [enumAccessLevel.PUBLIC, enumAccessLevel.PUBLIC_IN_PRACTICE];
  export const patientAccessLevels = [enumAccessLevel.PATIENT, enumAccessLevel.PATIENT_UNAUTHENTICATED, enumAccessLevel.PATIENT_IN_PRACTICE];
  export const pipAccessLevels = [enumAccessLevel.PUBLIC_IN_PRACTICE, enumAccessLevel.PATIENT_IN_PRACTICE];
  export const manageAppAccessLevels = [enumAccessLevel.MANAGE_UI, enumAccessLevel.PRACTICE_ADMIN];
  export enum enumJWTVersion {
    CURRENT = "2.2",
  }
}
