export function _getTaskReminderHourSent(task_reminder_sent_at: Date | null): number | null {
  if (task_reminder_sent_at) return new Date(task_reminder_sent_at).getHours();

  return null;
}

export class Metric {
  public timestamp = new Date().toISOString();
  public region: string | undefined;
  constructor(public metricName: string, site_id: string | null, public properties: Record<string, any> = {}) {
    if (site_id) this.properties.site_id = site_id;
    this._setReferrer();
    this._checkProperties(); //should be last
  }

  private _setReferrer() {
    // Parse the referrer if it's a stringified JSON
    try {
      if (this.properties.referrer && typeof this.properties.referrer === "string" && this.properties.referrer.includes("{")) {
        this.properties.referrer = JSON.parse(this.properties.referrer);
      }
    } catch (e) {
      console.error("7f9b1f5a Unable to parse referrer", this.properties.referrer, e);
    }

    if (this.properties.referrer?.type) {
      if (this.properties.referrer.task_reminder_sent_at) {
        this.properties.hour_sent = _getTaskReminderHourSent(this.properties.referrer.task_reminder_sent_at);
        if (this.properties.hour_sent === null) {
          console.error("1w0k5z0d null hour_sent", JSON.stringify(this.properties));
        }
      }

      this.properties.referrer = this.properties.referrer.type;
    }
  }

  private _isPropertyValid(property: string | string[] | null | undefined): boolean {
    if (property === undefined) return false;
    if (property === null) return false;
    if (typeof property === "string") {
      if (property.includes("{")) return false;
    }

    if (typeof property === "object" && !Array.isArray(property)) {
      return false;
    }

    return true;
  }

  private _checkProperties(): void {
    const invalidPropertiesString = Object.entries(this.properties)
      .filter((entry) => !this._isPropertyValid(entry[1]))
      .map(([key, value]) => `${key}: ${value}`)
      .join();

    if (invalidPropertiesString) {
      console.error(`Tracking metric ${this.metricName} but it has invalid properties ${invalidPropertiesString}`);
    }
  }
}
